import { useEffect, useState } from "react";

// mui
import {
  Box,
  Button,
  Dialog,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Editor from "@monaco-editor/react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PropTypes from "prop-types";

// table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import General from "api/general";
import { verdictColor } from "util";
import { notify } from "util";

const useClasses = (theme) => ({
  button: {
    mt: theme.spacing(1),
  },
  itemSpacing: {
    pt: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    pt: theme.spacing(3),
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CustomizedAccordions = ({ title, data, headCells }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DenseTable rows={data} headCells={headCells} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const headCells = [
  { id: "case", numeric: true, disablePadding: false, label: "Case #" },
  { id: "cpu", numeric: true, disablePadding: false, label: "Time(ms)" },
  { id: "memory", numeric: true, disablePadding: false, label: "Memory(KB)" },
  { id: "status", numeric: false, disablePadding: false, label: "Verdict" },
];

const DenseTable = ({ headCells, rows }) => {
  console.log(rows);
  if (rows.length === 0) {
    return <Typography>Ops! No testcases available</Typography>;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell, index) => (
              <TableCell
                key={index}
                align={headCell.id === "case" ? "left" : "center"}
                padding={"none"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.number}</TableCell>
              <TableCell align="center">{row.time}</TableCell>
              <TableCell align="center">{row.memory.toFixed(1)}</TableCell>
              <TableCell align="center">{row.verdict}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function SubmissionStatusDialog({
  open,
  handleClose,
  problemName,
  language,
  source,
  type,
  id,
}) {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [submissionResult, setSubmissionResult] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = 10000; // worst case time limit 10s for the response to arrive
    const api = new General(timeout);

    switch (type) {
      case "submit":
        api
          .submitProblem(problemName, language, source)
          .then((res) => {
            console.log(res);
            setSubmissionResult(res.message);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError(err.message || "Something went wrong! Try again later");
            setLoading(false);
          });
        break;

      case "submitTest":
        api
          .submitProblemTest(problemName, language, source)
          .then((res) => {
            console.log(res);
            setSubmissionResult(res.message);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError(err.message || "Something went wrong! Try again later");
            setLoading(false);
            notify(err, "error");
          });
        break;

      case "view":
        console.log(id, "sdsdasdasd");
        api
          .getSubmission(id)
          .then((res) => {
            console.log(res);
            setSubmissionResult(res.message);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError(err || "Something went wrong! Try again later");
            setLoading(false);
          });
        break;

      default:
        console.log("incorrect type of submission");
        setError("incorrect type of submission");
        setLoading(false);

        break;
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={"paper"}
      maxWidth={"md"}
    >
      <Box
        sx={{
          mx: 5,
          my: 1,
          minWidth: "500px",
        }}
      >
        <Box sx={classes.itemSpacingMedium}>
          <Typography sx={{ textAlign: "center" }} variant="h3">
            Submission Status
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Divider />
        </Box>
        <Box sx={classes.itemSpacingLarge}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ textAlign: "left" }} variant="h5">
                Problem
              </Typography>
              <Typography sx={{ textAlign: "left" }} variant="body1">
                {problemName || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ textAlign: "left" }} variant="h5">
                Submitted
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={160} height={30} />
              ) : (
                <Typography sx={{ textAlign: "left" }} variant="body1">
                  {new Date(
                    submissionResult?.status?.submittedAt
                  ).toLocaleString()}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.itemSpacingLarge}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ textAlign: "left" }} variant="h5">
                Verdict
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={160} height={30} />
              ) : (
                <Typography
                  sx={{
                    textAlign: "left",
                    color: verdictColor(
                      submissionResult?.status?.verdict,
                      theme
                    ),
                  }}
                  variant="body1"
                >
                  {submissionResult?.status?.verdict}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ textAlign: "left" }} variant="h5">
                Testcases passed
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={160} height={30} />
              ) : (
                <Typography sx={{ textAlign: "left" }} variant="body1">
                  {submissionResult?.status?.totalTestcasesPassed +
                    "/" +
                    submissionResult?.status?.totalTestcases}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        {submissionResult?.source && (
          <>
            <Box
              height={theme.spacing(40)}
              sx={{
                mt: theme.spacing(3),
                borderRadius: 5,
              }}
            >
              <Editor
                theme={"vs-dark"}
                defaultLanguage="c"
                language={submissionResult?.language}
                value={submissionResult?.source}
                defaultValue={submissionResult?.source}
                options={{
                  codeLens: false,
                  theme: "vs-dark",
                  folding: false,
                  renderIndentGuides: false,
                  lineNumbers: false,
                  glyphMargin: false,
                  readOnly: true,
                  fontSize: 15,
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            </Box>
            <div
              style={{
                marginTop: theme.spacing(2),
              }}
            />
            <CustomizedAccordions
              title={"Test cases"}
              data={submissionResult?.status?.testcases}
              headCells={headCells}
            />
          </>
        )}

        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

SubmissionStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  problemName: PropTypes.string,
  language: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.oneOf(["submit", "submitTest", "view"]).isRequired,
  id: PropTypes.string,
};

DenseTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

CustomizedAccordions.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
};
