import { Box } from "@mui/system";
import * as React from "react";
import { Tab, TableCell, tableCellClasses, Tabs } from "@mui/material";
import styled from "@emotion/styled";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

const StyledNavbar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.main.lightAsh,
  my: 0,
  mx: 0,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: "250px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.main.background,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: theme.palette.main.green,
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.main.black,
  })
);

const FilterLanguage = ({
  items,
  setLanguage,
  language,
  setCode,
  codeSnippets,
}) => {
  const handleChange = (event) => {
    setLanguage(event.target.value);
    setCode(codeSnippets[event.target.value.toLowerCase()]);
  };

  console.log(language);

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Select
        value={language}
        onChange={handleChange}
        //displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: 35,
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterLanguage.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  setLanguage: PropTypes.func,
  language: PropTypes.string,
  setCode: PropTypes.func,
  codeSnippets: PropTypes.object,
};

export {
  StyledTableCell,
  StyledNavbar,
  StyledTabs,
  StyledTab,
  a11yProps,
  FilterLanguage,
};
