import { Box, Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ANNOUNCEMENTS } from "constants/route";
import NoticeBox from "components/NoticeBox";
import ActiveSolver from "components/ActiveSolver";
import BlogPost from "components/BlogPost";
import SingleBox from "pages/LandingPage/SingleBox";
import StyledChip from "components/StyledChip";
import FilterMenu from "components/FilterMenu";
import { useEffect, useState } from "react";
import General from "api/general";
import { Helmet } from "react-helmet-async";

const HeaderBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  textAlign: "left",
  boxShadow: theme.customShadows.primary,
  height: "90px",
}));

const LandingPage = () => {
  const [filterList, setFilterList] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [landingPageFilter, setLandingPageFilter] = useState([]);
  const [pinnedNotice, setPinnedNotice] = useState([]);
  const [raw, setRaw] = useState([]); // this is the raw data that comes from the server
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const api = new General();
    api.listBlogs().then((res) => {
      setFilterList(res.message);
      setRaw(res.message);
      setLoading(false);
    });
    api.listBlogType().then((res) => {
      setLandingPageFilter(res.message.blogTypes);
    });

    api.listPinnedAnouncement().then((res) => {
      setPinnedNotice(res.message);
      console.log(res.message);
    });
  }, []);

  const handleChipChange = (event, value) => {
    console.log(value);
    if (value.length) {
      const temp = [];

      raw.forEach((row) => {
        for (let index = 0; index < row.tags.length; index++) {
          if (value.includes(row.tags[index].name)) {
            temp.push(row);
          }
        }
      });

      setFilterList(temp);
    } else {
      setFilterList(raw);
    }
  };

  if (loading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant="text" height={40} />
        <Skeleton
          variant="rectangular"
          height={118}
          sx={{
            borderRadius: "10px",
          }}
        />
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
        <title>AUST OJ</title>
      </Helmet>
      <Grid container spacing={2}>
        {/* left content */}
        <Grid item xs={12} lg={9} md={8}>
          <HeaderBox sx={{ pl: 3 }}>
            <FilterMenu
              name="Filter By"
              items={landingPageFilter}
              width={210}
              selectedList={chipData}
              setSelectedList={setChipData}
              onInputChange={handleChipChange}
              isMultiple
              defaultReplaceValue={"Filter By"}
            />
            {chipData.length > 0 && <Box sx={{ mt: 2 }} />}
            {chipData.map((data, index) => {
              return <StyledChip key={index} label={data} size="small" />;
            })}
          </HeaderBox>
          <Box sx={{ mt: 2 }} />

          {filterList.map((post, index) => (
            <BlogPost key={index} post={post} />
          ))}

          {filterList.length === 0 && (
            <Box
              sx={{
                borderRadius: "10px",
                padding: "15px 30px 25px 30px",
                marginTop: "16px",
                height: "335px",
                boxShadow: (theme) => theme.customShadows.primary,
                backgroundColor: (theme) => theme.palette.main.white,
                textAlign: "center",
                pt: "30px",
              }}
            >
              <Typography variant="h2">No blogs posts yet!</Typography>
            </Box>
          )}
        </Grid>

        {/* right content */}
        <Grid item xs={12} lg={3} md={4}>
          <SingleBox
            title="Announcements"
            iconName="announcement"
            to={ANNOUNCEMENTS}
          />
          <Box sx={{ mt: 2 }} />
          <NoticeBox title="Pinned" notices={pinnedNotice} />
          {/* <Box sx={{ mt: 2 }} />
        <UpcomingBox title="Upcoming Contests" items={contests} /> */}
          <Box sx={{ mt: 2 }} />
          <ActiveSolver />
        </Grid>
      </Grid>
    </>
  );
};

LandingPage.propTypes = {};

export default LandingPage;
