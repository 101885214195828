import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import TextInputLayout from "components/TextInputLayout";
import StyledChip from "components/StyledChip";
import FilterMenu from "components/FilterMenu";
import { getCurrentUser } from "config/auth";
import LeaderboardsTable from "./LeaderboardTable";
import PropTypes from "prop-types";
import General from "api/general";
import Constants from "api/constants";
import { notify } from "util";
import { Helmet } from "react-helmet-async";

// // will come from server
// const filterItems = {
//   batch: ["1.1", "1.2", "2.1", "2.2", "3.1", "3.2", "4.1", "4.2"],
//   department: ["CSE", "ECE", "EEE", "ME", "CE", "CHE", "MCA", "MBA"],
//   institution: ["AUST", "AIUB", "BUET", "CUET", "DU", "SUST", "IUT", "JU"],
// };

const SWITCH = ["AUST", "OJ"];

const headCells = {
  AUST: [
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      label: "# Rank",
    },
    {
      id: "handle",
      numeric: false,
      disablePadding: false,
      label: "Handle",
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: false,
      label: "Full Name",
    },
    {
      id: "department",
      numeric: false,
      disablePadding: false,
      label: "Department",
    },
    {
      id: "batch",
      numeric: false,
      disablePadding: false,
      label: "Batch",
    },

    {
      id: "tags",
      numeric: false,
      disablePadding: false,
      label: "Tag",
    },
    {
      id: "solved",
      numeric: true,
      disablePadding: false,
      label: "Solved",
    },
  ],
  OJ: [
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      label: "# Rank",
    },
    {
      id: "handle",
      numeric: false,
      disablePadding: false,
      label: "Handle",
    },
    {
      id: "fullName",
      numeric: false,
      disablePadding: false,
      label: "Full Name",
    },
    {
      id: "institution",
      numeric: false,
      disablePadding: false,
      label: "Institution",
    },
    {
      id: "solved",
      numeric: true,
      disablePadding: false,
      label: "Solved",
    },
  ],
};

const HeaderBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  textAlign: "left",
  boxShadow: theme.customShadows.primary,
}));

const CustomSwitch = ({ selected, setSelected, handleChange }) => {
  const theme = useTheme();

  const handleSwitchChange = (event) => {
    setSelected(event.target.name);
    handleChange(event.target.name);
  };

  return (
    <Stack direction="row">
      <Button
        variant="contained"
        onClick={handleSwitchChange}
        name={SWITCH[0]}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow: "none",
          color:
            selected === SWITCH[0]
              ? theme.palette.main.white
              : theme.palette.main.black,
          backgroundColor:
            selected === SWITCH[0]
              ? theme.palette.main.green
              : theme.palette.main.lightAsh,
          // remove hover effect
          "&:hover": {
            backgroundColor:
              selected === SWITCH[0]
                ? theme.palette.main.green
                : theme.palette.main.lightAsh,
          },
        }}
      >
        {SWITCH[0]}
      </Button>
      <Button
        name={SWITCH[1]}
        variant="contained"
        onClick={handleSwitchChange}
        sx={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          color:
            selected === SWITCH[1]
              ? theme.palette.main.white
              : theme.palette.main.black,
          backgroundColor:
            selected === SWITCH[1]
              ? theme.palette.main.green
              : theme.palette.main.lightAsh,
          // remove hover effect
          "&:hover": {
            backgroundColor:
              selected === SWITCH[1]
                ? theme.palette.main.green
                : theme.palette.main.lightAsh,
          },
        }}
      >
        {SWITCH[1]}
      </Button>
    </Stack>
  );
};

const fetchData = async (url) => {
  const api = new General();
  try {
    const data = await api.listLeaderboard(url.toLowerCase());
    return data;
  } catch (error) {
    notify(error, "error");
    return null;
  }
};

export default function LeaderboardPage() {
  const theme = useTheme();
  const [chipDataDept, setChipDataDept] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [chipDataBatch, setChipDataBatch] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [form, setForm] = React.useState({});
  const [selectedSwitch, setSelectedSwitch] = React.useState(SWITCH[1]);
  const [raw, setRaw] = React.useState(null); // this is the raw data that comes from the server
  const [error, setError] = React.useState(false);
  const [filterItems, setFilterItems] = React.useState([]);

  useEffect(() => {
    // check permission of user
    const currentUser = getCurrentUser();
    setUser(currentUser);

    const api = new Constants();
    api
      .getConstants("universities")
      .then((res) => {
        setFilterItems(res.message.universities);
      })
      .catch((err) => {
        console.log(err);
      });

    // if not AUST user, then set selected switch to OJ
    if (currentUser.profile && currentUser.profile.institution === SWITCH[0]) {
      setSelectedSwitch(SWITCH[0]);
      fetchData(SWITCH[0])
        .then((data) => {
          setRaw(data.message);
          setFilteredData(data.message);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
          setRaw([]);
          notify(error, "error");
        });
    } else {
      fetchData(SWITCH[0])
        .then((data) => {
          setSelectedSwitch(SWITCH[1]);
          setRaw(data.message);
          setFilteredData(data.message);
        })
        .catch((error) => {
          console.log(error);
          setError(true);
          setRaw([]);
        });
    }
  }, []);

  const onInputChange = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSwitchChange = (value) => {
    fetchData(value)
      .then((data) => {
        setRaw(data.message);
        setFilteredData(data.message);
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
      });
  };

  const handleSearchClick = () => {
    console.trace(raw);

    const temp = raw.filter((row) => {
      return row.handle
        .toLowerCase()
        .includes(form["handleSearch"].toLowerCase());
    });

    setFilteredData(temp);
  };

  /*
  const handleChipChange = (event, value) => {
    const filterType = event.target.name.toLowerCase();

    if (value.length) {
      let temp = [];
      let batchList = [];
      let dept = "";

      if (filterType === "batch") {
        batchList = value;
        dept = chipDataDept[0];
      } else {
        batchList = chipDataBatch;
        dept = value;
      }

      temp = raw.filter((row) => {
        return (
          (batchList.length == 0 || batchList.includes(row.batch)) &&
          dept === row.department
        );
      });

      setFilteredData(temp);
    } else {
      setFilteredData(raw);
    }
  };
  */

  const handleChipChangeInstitution = (event, value) => {
    const filterType = event.target.name.toLowerCase();

    const temp = raw.filter((row) => value.includes(row[filterType].acronym));
    if (value.length > 0) {
      setFilteredData(temp);
    } else {
      setFilteredData(raw);
    }
  };

  if (error) {
    return <div>Error...</div>;
  }

  if (!raw) {
    return <Skeleton height={60} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Leaderboard</title>
      </Helmet>
      {/* header box */}
      <HeaderBox sx={{ px: 3, display: "flex" }}>
        <Typography variant="h3" gutterBottom paddingTop={1}>
          Leaderboard
        </Typography>
        <Box flexGrow={1} />
        {user.profile && user.profile.institution === SWITCH[0] && (
          <CustomSwitch
            setSelected={setSelectedSwitch}
            selected={selectedSwitch}
            handleChange={onSwitchChange}
          />
        )}
      </HeaderBox>
      <Box sx={{ mt: 2 }} />
      {/* body */}
      <Box
        sx={{
          borderRadius: 1,
          boxShadow: theme.customShadows.primary,
          px: 3,
          py: 2,
          backgroundColor: theme.palette.main.white,
        }}
      >
        <Grid container spacing={2} alignItems="flex-start" direction="row">
          <Grid item>
            <TextInputLayout
              placeholder="Handle"
              type="search"
              name="handleSearch"
              onInputChange={onInputChange}
              width={30}
              height={60}
              padding={1}
              onActionClick={handleSearchClick}
              onButtonPress={handleSearchClick}
            />
          </Grid>
          {selectedSwitch === SWITCH[0] && (
            <React.Fragment>
              {/* 
              !todo: there is a bug in filtermenu department. So, im just removing it for the current release.
              <Grid item>
                <FilterMenu
                  items={filterItems.department}
                  placeholder="Select Department"
                  name="Department"
                  width={220}
                  selectedList={chipDataDept}
                  setSelectedList={setChipDataDept}
                  onInputChange={handleChipChange}
                />
              </Grid> */}

              {/* <Grid item>
                <FilterMenu
                  items={filterItems.batch}
                  placeholder="Select Batch"
                  name="Batch"
                  width={200}
                  selectedList={chipDataBatch}
                  setSelectedList={setChipDataBatch}
                  onInputChange={handleChipChange}
                  isMultiple
                />
              </Grid> */}
            </React.Fragment>
          )}

          {selectedSwitch === SWITCH[1] && (
            <React.Fragment>
              <Grid item>
                <FilterMenu
                  items={filterItems}
                  placeholder="Select Institution"
                  name="Institution"
                  width={200}
                  selectedList={chipDataDept}
                  setSelectedList={setChipDataDept}
                  onInputChange={handleChipChangeInstitution}
                  isMultiple
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Box sx={{ mt: 2 }}>
          {/* batch */}
          {chipDataBatch.map((data, index) => {
            return (
              <StyledChip
                key={index}
                label={data}
                variant="outlined"
                color="success"
                size="small"
              />
            );
          })}
          {/* dept */}

          {chipDataDept.map((data, index) => {
            return <StyledChip key={index} label={data} size="small" />;
          })}
        </Box>

        <LeaderboardsTable
          data={filteredData}
          user={user}
          headCells={headCells[selectedSwitch]}
          caller={selectedSwitch}
        />
      </Box>
    </React.Fragment>
  );
}

LeaderboardPage.propTypes = {};

CustomSwitch.propTypes = {
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
