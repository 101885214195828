// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TotalCalculation_problemSolvedConatiner__\\+vioc {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.TotalCalculation_problemSolvedConatiner2__Ka\\+RQ {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n}\n\n.TotalCalculation_problemSolved__zHyki {\n  padding-top: 24px;\n  font-weight: bold;\n  color: var(--color-blackShadeFont);\n}\n\n.TotalCalculation_problemSolvedCount__5qhvw {\n  color: var(--color-darkAsh);\n}\n\n.TotalCalculation_arrow__9l5\\+P {\n  color: var(--color-black);\n  width: 17px;\n  height: 17px;\n  margin-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/components/TotalCalculation.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".problemSolvedConatiner {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.problemSolvedConatiner2 {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n}\n\n.problemSolved {\n  padding-top: 24px;\n  font-weight: bold;\n  color: var(--color-blackShadeFont);\n}\n\n.problemSolvedCount {\n  color: var(--color-darkAsh);\n}\n\n.arrow {\n  color: var(--color-black);\n  width: 17px;\n  height: 17px;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"problemSolvedConatiner": "TotalCalculation_problemSolvedConatiner__+vioc",
	"problemSolvedConatiner2": "TotalCalculation_problemSolvedConatiner2__Ka+RQ",
	"problemSolved": "TotalCalculation_problemSolved__zHyki",
	"problemSolvedCount": "TotalCalculation_problemSolvedCount__5qhvw",
	"arrow": "TotalCalculation_arrow__9l5+P"
};
export default ___CSS_LOADER_EXPORT___;
