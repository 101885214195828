import { parseError, parseResponse } from "util/payloadHandler";
import Api from "./api";

export default class AuthUser extends Api {
  constructor() {
    super();
    this.init("/api/v1/general/auth-user/settings");
  }

  async getProfileInfo() {
    try {
      const res = await this.client.get(`/profile`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async putProfileInfo(data) {
    try {
      const res = await this.client.put(`/profile`, data);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getOnlineJudges() {
    try {
      const res = await this.client.get(`/onlineJudge`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async putOnlineJudges(data) {
    try {
      const res = await this.client.put(`/onlineJudge`, data);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getSocials() {
    try {
      const res = await this.client.get(`/socialAccount`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async putSocials(data) {
    try {
      const res = await this.client.put(`/socialAccount`, data);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async changePassword(data) {
    try {
      const res = await this.client.put(`/changePassword`, data);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }
}
