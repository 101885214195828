import { useTheme } from "@emotion/react";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { PropTypes } from "prop-types";

/**
 *
 * @param {String} title  header name
 * @param {String} iconName  [optional] the name of the icon to be displayed
 * @param {String} to the route to be navigated to
 */
const ContestBox = ({ title, labels, startsOn, length }) => {
  const theme = useTheme();
  //const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: theme.palette.main.lightAsh2,
        borderRadius: theme.spacing(1),
        px: theme.spacing(3),
        py: theme.spacing(3),
      }}
    >
      {/* left content */}
      <Grid item xs={12} lg={9} md={5}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={theme.typography.fontWeightSemiBold}
          sx={{ mb: 1 }}
        >
          {title}
        </Typography>
        {labels.map((label, index) => (
          <Chip
            key={index}
            label={label.name}
            sx={{ backgroundColor: label.color, mr: 1 }}
            size="small"
          />
        ))}
      </Grid>

      {/* right content */}
      <Grid item xs={12} lg={3} md={7}>
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ pr: 2 }}>
            <Typography variant="h4" color="secondary">
              <b>Starts on</b>
            </Typography>
            <Typography variant="h4" color="secondary">
              {startsOn}
            </Typography>
            <Typography variant="h4" color="secondary">
              <b>Length</b>
            </Typography>
            <Typography variant="h4" color="secondary">
              {length}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" color="secondary">
              Register
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ContestBox.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  remaining: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  startsOn: PropTypes.string.isRequired,
};

export default ContestBox;
