import React from "react";
import Cookies from "universal-cookie";
const { Navigate } = require("react-router-dom");
import PropTypes from "prop-types";
import { HOME } from "constants/route";
import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN } from "config/env";

const getCurrentUser = () => {
  const cookies = new Cookies();
  const token = cookies.get(ACCESS_TOKEN);
  if (!token) {
    return {
      isAuthenticated: false,
      profile: null,
    };
  }
  var decodedToken = jwt_decode(token);

  return {
    isAuthenticated: true,
    profile: {
      sub: decodedToken.sub,
      handle: decodedToken.handle,
      email: decodedToken.email,
      institution: decodedToken.institution,
      roles: decodedToken.roles,
      avatar: decodedToken.avatar,
    },
  };
};

function RequireAuth({ children }) {
  const user = getCurrentUser();

  if (user.isAuthenticated) {
    console.log("auth cookie found!");
    return children;
  } else {
    console.log("auth cookie NOT found!");
    return <Navigate to={HOME} replace />;
  }
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
  parent: PropTypes.string,
};
export { getCurrentUser };

export default RequireAuth;
