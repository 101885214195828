// ----------------------------------------------------------------------

export default function Menu(theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        },
      },
    },
  };
}
