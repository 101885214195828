import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Profile from "./components/Profile";
import OnlineJudges from "./components/OnlineJudges";
import Socials from "./components/Socials";
import Settings from "./components/Settings";
import IconMapper from "components/IconMapper";
import { Helmet } from "react-helmet-async";

const useClasses = (theme) => ({
  tab: {
    [theme.breakpoints.up("sm")]: {
      mr: 3, // REMARK: mobile view
    },
    color: theme.palette.main.blackShade,
    fontWeight: "bold",
    justifyContent: "start",
    borderRadius: "5px",
  },
  button: {
    p: theme.spacing(2),
  },

  itemSpacingMedium: {
    py: theme.spacing(2),
  },

  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SettingsPage = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [value, setValue] = React.useState(0);
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
        display: isMobileView ? "" : "flex", // REMARK: mobile view
        px: 3,
        py: 4,
      }}
    >
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Tabs
        orientation={isMobileView ? "horizontal" : "vertical"} // REMARK: mobile view
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 4,
          borderColor: theme.palette.main.lightAsh,
          minWidth: isMobileView ? "" : "fit-content", // REMARK: mobile view
        }}
      >
        <Tab
          sx={classes.tab}
          label="Profile"
          icon={<IconMapper name="user" size="25px" gutter={1} />}
          iconPosition="start"
          wrapped
          {...a11yProps(0)}
        />
        <Tab
          sx={classes.tab}
          label="Online Judges"
          icon={<IconMapper name="judge" size="25px" gutter={1} />}
          iconPosition="start"
          wrapped
          {...a11yProps(1)}
        />
        <Tab
          sx={classes.tab}
          label="Settings"
          icon={<IconMapper name="settings" size="25px" gutter={1} />}
          iconPosition="start"
          wrapped
          {...a11yProps(2)}
        />
        <Tab
          sx={classes.tab}
          label="Social"
          icon={<IconMapper name="social" size="25px" gutter={1} />}
          iconPosition="start"
          wrapped
          {...a11yProps(3)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OnlineJudges />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Settings />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Socials />
      </TabPanel>
    </Box>
  );
};

SettingsPage.propTypes = {};

export default SettingsPage;
