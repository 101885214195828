import React from "react";
import icon404 from "assets/notFound.svg";
import { HOME } from "constants/route";

// mui
import { Box, Paper, Button, Typography, useTheme } from "@mui/material";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 600,
    px: theme.spacing(8),
    pb: theme.spacing(2),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: theme.customShadows.primary,
  },
  button: {
    px: theme.spacing(0),
    py: 1,
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const NotFound = () => {
  const theme = useTheme();
  const classes = useClasses(theme);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Paper sx={classes.paper}>
        <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            pt: theme.spacing(9),
            mx: "auto",
          }}
          alt="The house from the offer."
          src={icon404}
        />
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h3">
            OOPS! PAGE NOT FOUND
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Typography sx={{ textAlign: "center" }} variant="body1">
            Sorry, the page you’re looking for doesn’t exists. It might have
            been moved or deleted.
          </Typography>
        </Box>
        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => (window.location.href = HOME)}
          >
            Redirect
          </Button>
        </Box>
        <Box sx={{ padding: theme.spacing(2) }} />
      </Paper>

      <Box sx={{ padding: theme.spacing(5) }} />
    </Box>
  );
};

export default NotFound;
