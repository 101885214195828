import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./TotalCalculation.module.css";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PropTypes from "prop-types";

const TotalCalculation = ({ totalCalculation }) => {
  const { solveCount, problemsAuthored, blogsAuthored } = totalCalculation;

  return (
    <Box>
      <Box
        className={styles.problemSolvedConatiner}
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <Typography variant="h3" component="div" gutterBottom>
          Problems Solved
        </Typography>
        <Typography
          className={styles.problemSolvedCount}
          variant="h2"
          gutterBottom
        >
          {solveCount || 0}
        </Typography>
      </Box>
      <Box
        className={styles.problemSolvedConatiner2}
        sx={{
          px: 4,
          py: 3,
          height: "250px"
        }}
      >
        <Typography variant="h3" component="div" gutterBottom>
          Problems Authored
        </Typography>
        <Typography
          className={styles.problemSolvedCount}
          variant="h2"
          gutterBottom
        >
          {problemsAuthored}
          <CallReceivedIcon className={styles.arrow}></CallReceivedIcon>
        </Typography>

        <Typography
          variant="h3"
          component="div"
          gutterBottom
          sx={{
            mt: 5,
          }}
        >
          Blogs Contributed
        </Typography>
        <Typography
          className={styles.problemSolvedCount}
          variant="h2"
          gutterBottom
        >
          {blogsAuthored}
          <CallReceivedIcon className={styles.arrow}></CallReceivedIcon>
        </Typography>
      </Box>
    </Box>
  );
};

TotalCalculation.propTypes = {
  totalCalculation: PropTypes.object.isRequired,
};

export default TotalCalculation;
