import palette from "./palette";

// a dedicated function that returns the default palette
// with the mode property set to "light" or "dark"
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light" ? palette : palette),
  },
});

export default palette;
export { getDesignTokens, palette };
