import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
//import Success from "./success";
import Fail from "./fail";
const VerificationPage = () => {
  //const navigate = useNavigate();
  return (
    <Box>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Fail />
    </Box>
  );
};

VerificationPage.propTypes = {};

export default VerificationPage;
