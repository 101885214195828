const { LinearProgress } = require("@mui/material");
import { Box } from "@mui/system";
import PropTypes from "prop-types";

// todo: doesnt fill up entire width since <Layout/> has padding
const LinearLoadingBar = ({ loading = true }) => {
  return (
    loading && (
      <Box width={"100%"} marginTop={-2}>
        <LinearProgress color="info" />
      </Box>
    )
  );
};

LinearLoadingBar.propTypes = {
  loading: PropTypes.bool,
};

export default LinearLoadingBar;
