import { parseError, parseResponse } from "util/payloadHandler";
import Api from "./api";

export default class Constants extends Api {
  constructor() {
    super();
    this.init("/api/v1/general/constants");
  }

  /**
   * Get the stored contents
   * @param {String} type any of [universities, departments]
   */
  async getConstants(type) {
    try {
      const res = await this.client.get(`/${type}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }
}
