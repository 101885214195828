import React, { useEffect } from "react";
// import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Divider,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import AuthUser from "api/authUser";
import { notify } from "util";

const useClasses = (theme) => ({
  button: {
    p: theme.spacing(2),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMarginBtm: {
    mb: theme.spacing(3),
  },
});

const Socials = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState({});
  const [socialAccounts, setSocialAccounts] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);

  useEffect(() => {
    const api = new AuthUser();
    api.getSocials().then((res) => {
      console.log(res.message.socialAccounts.length);
      if (res.message.socialAccounts.length > 0) {
        setSocialAccounts(res.message.socialAccounts);
      } else {
        setSocialAccounts([
          {
            social: "Facebook",
          },
          {
            social: "Twitter",
          },
          {
            social: "LinkedIn",
          },
          {
            social: "Github",
          },
        ]);
      }
      console.log(res.message.socialAccounts);
    });
  }, []);

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    const newSocialAccounts = socialAccounts.map((socialAccount) => {
      const { social } = socialAccount;
      if (form[social]) {
        const newSocialAccount = {
          ...socialAccount,
          userName: form[social],
        };
        return newSocialAccount;
      }
      return socialAccount;
    });

    console.log(newSocialAccounts);
    const api = new AuthUser();
    setProcessing(true);
    api
      .putSocials({ socialAccounts: newSocialAccounts })
      .then((res) => {
        console.log(res);
        setProcessing(false);
        notify(res.message, "success");
      })
      .catch((err) => {
        notify(err, "error");
        setProcessing(false);
      });
    console.log(form);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Social Accounts
      </Typography>
      <Divider />
      <Box sx={classes.itemSpacingMedium}>
        <Grid container spacing={2}>
          {socialAccounts &&
            socialAccounts.map((item, index) => (
              <Grid item xs={12} lg={4} key={index}>
                <Box>
                  <Typography variant="h4" gutterBottom paddingTop={1}>
                    {item.social}
                  </Typography>
                  <TextInputLayout
                    // icon="mail"
                    placeholder={"Enter " + item.social + " link"}
                    type="text"
                    name={item.social}
                    value={item.userName}
                    onInputChange={onInput}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
        <Box sx={classes.itemSpacingLarge}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            onClick={onSubmitClick}
            disabled={processing}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Socials.propTypes = {};

export default Socials;
