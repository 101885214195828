import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Chip, Divider, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { StyledTableCell, StyledTableRow } from "./Cell";
import { useNavigate } from "react-router-dom";
import randomColor from "randomcolor";

function descendingComparator(a, b, orderBy) {
  // since institution field is an object of {name, acronym} we need to compare the acronym field
  if (orderBy === "institution") {
    if (a.institution.acronym < b.institution.acronym) {
      return -1;
    }
    if (a.institution.acronym > b.institution.acronym) {
      return 1;
    }
    return 0;
  }

  // for the remaining fields we can just compare the value since they are just strings
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "handle" ||
                headCell.id == "fullName" ||
                headCell.id == "rank"
                  ? "left"
                  : "center"
              }
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h6" color="secondary">
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default function LeaderboardsTable({ data, user, headCells, caller }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("rank");
  const [page, setPage] = React.useState(0);
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, backgroundColor: "transparent" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              user={user}
              headCells={headCells}
            />

            <TableBody
              sx={{
                backgroundColor: "transparent",
              }}
            >
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    px: 0,
                    pt: 1,
                    pb: 2,
                  }}
                >
                  <Divider />
                </TableCell>
              </TableRow>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      tabIndex={-1}
                      key={row.id || index}
                      sx={{
                        // !todo: @matcovic fix the gap problem
                        borderBottom: 5,
                        borderColor: theme.palette.main.white,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                          navigate(`/profile?handle=${row.handle}`);
                        }}
                    >
                      <StyledTableCell
                        component="th"
                        id={index}
                        scope="row"
                        align="left"
                      >
                        {row.rank}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        // onClick={() => {
                        //   navigate(`/profile/${row.handle}`);
                        // }}
                      >
                        {row.handle}
                      </StyledTableCell>
                      <StyledTableCell>{row.fullName || "N/A"}</StyledTableCell>

                      {caller == "AUST" ? (
                        <React.Fragment>
                          <StyledTableCell align="center">
                            {row.department || "N/A"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.batch || "N/A"}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.tags &&
                              row.tags.map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={tag}
                                  size="small"
                                  color="primary"
                                  sx={{
                                    ml: index > 0 ? 1 : 0,
                                    backgroundColor: randomColor({
                                      hue: "blue",
                                      luminosity: "dark",
                                      seed: tag,
                                    }),
                                  }}
                                />
                              ))}
                          </StyledTableCell>
                        </React.Fragment>
                      ) : (
                        <StyledTableCell align="center">
                          {row.institution}
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center">
                        {row.solved}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  user: PropTypes.object,
  headCells: PropTypes.array,
};

LeaderboardsTable.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object,
  headCells: PropTypes.array,
  caller: PropTypes.oneOf(["AUST", "OJ"]),
};
