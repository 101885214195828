/* eslint-disable no-unused-vars */
import { Button, Stack, Typography, useTheme } from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import React from "react";
import BlogPost from "components/BlogPost";
import ClarificationBox from "components/ClarificationBox";
import ContestBox from "components/ContestBox";
import ProblemsTable from "./ProblemsTable";
import FilterMenu from "components/FilterMenu";

/**
 *
 * A dummy page showing all necessary components and text styles
 */

const useClasses = (theme) => ({
  button: {
    backgroundColor: theme.palette.main.blackShade,
    borderColor: theme.palette.main.lightPurple,
    maxWidth: theme.spacing(40),
    padding: 2,
    // add hover effect
    "&:hover": {
      backgroundColor: theme.palette.main.white,
      borderColor: theme.palette.main.lightAsh,
    },
    // add media query
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.main.white,
      borderColor: theme.palette.main.lightAsh,
    },
  },
});

const post = {
  title: "Headline 1",
  categories: [
    "titletitletitle",
    "title",
    "title",
    "title",
    "title",
    "title",
    "title",
    "title",
    "title",
    "title",
  ],
  name: "title",
  time: "title",
  details: `Greetings to all humans and robots on this site!

  sevlll777, crazyilian, Mangooste, imachug and me (Alexdat2000) invite everyone to participate in the Codeforces Round #770 (Div. 2), which will take place this Sunday, February 6, 2022 at 20:35UTC+6. This round will be rated for all participants with a rating of strictly less than 2100. You will have 2 hours and 30 minutes to solve 6 problems. There will be an interactive problem in the round, so we recommend all new participants to read Interactive Problems Guide.
  
  The traditional thank-you list:
  
  Thanks to antontrygubO_o for coordinating us for a very long time and helping to improve one of the tasks
  Thanks to alexxela12345 for tasks that did not survive till the final version of the round
  Thanks to Pechalka, alexxela12345, Kirill22, physics0523, PurpleCrayon, teraqqq, tem_shett, FairyWinx, Dart-Xeyter, Vladithur, Igorfardoc, despair_101, shishyando, Brahma_tet and KROL_fsfjbj for quality testing of the round and very helpful feedback
  Thanks to MikeMirzayanov for the Codeforces and Polygon
  Scoring distribution: 500 — 1250 — 1500 — 2000 — 2500 — 3000.
  `,
};

// title, labels, to, startsOn, length, remaining
const contest = {
  title: "Codeforces Round #770 (Div. 2)",
  labels: [
    {
      name: "Codeforces",
      color: "#00bcd4",
    },
    {
      name: "Codeforces2",
      color: "#770 ",
    },
  ],
  to: "2020-02-06T20:35:00.000Z",
  startsOn: "Ferbruary 6, 2020, 06:35PM",
  length: "2h 30m",
  remaining: "2h 30m",
};

const items = ["Easy", "Medium", "Hard"];

const StoryBook = () => {
  const theme = useTheme();
  const classes = useClasses(theme);

  const [form, setForm] = React.useState(null);

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(form);
  };

  const onSubmitClick = () => {
    console.log("submit clicked", form);
  };

  const language = ["C", "C++", "Java"];

  const [list, setList] = React.useState([]);

  const [selectedItem, setSelectedItem] = React.useState("");

  const handleChipChange = (chipData) => {
    setList(chipData);
  };

  return (
    <Stack>
      {/* <FilterMenu
        items={items}
        placeholder="Select Difficulty"
        name="Difficulty"
        isMultiple
      /> */}

      <FilterMenu
        items={language}
        placeholder="Select Language"
        name="Language"
        width={180}
        item={selectedItem}
        setItem={setSelectedItem}
        onInputChange={handleChipChange}
      />

      <ProblemsTable />
      <TextInputLayout
        placeholder="Enter title"
        type="search"
        name="title"
        padding={1}
      />
      <ClarificationBox onInput={onInput} onSubmitClick={onSubmitClick} />
      <ContestBox
        title={contest.title}
        labels={contest.labels}
        to={contest.to}
        startsOn={contest.startsOn}
        length={contest.length}
        remaining={contest.remaining}
      />
      <Typography variant="h1">Headline 1</Typography>
      <Typography variant="h2">Headline 2</Typography>
      <Typography variant="h3">Headline 3</Typography>
      <Typography variant="h4">Headline 4</Typography>
      <Typography variant="h5">Headline 5</Typography>
      <Typography variant="h6">Headline 6</Typography>
      <Typography variant="subtitle1">Subtitle 1</Typography>
      <Typography variant="subtitle2">Subtitle 2</Typography>
      <Typography variant="body1">Body 1</Typography>
      <Typography variant="body2">Body 2</Typography>

      <Typography variant="caption">Caption</Typography>
      <Typography variant="overline">Overline</Typography>
      <Typography variant="button">Button</Typography>

      <Button variant="outlined" sx={classes.button}>
        Dummy
      </Button>
      <TextInputLayout
        icon="mail"
        placeholder="Email"
        type="email"
        name="email"
        onInputChange={onInput}
      />
      <TextInputLayout
        icon="lock"
        placeholder="Password"
        type="password"
        name="password"
        onInputChange={onInput}
      />

      <BlogPost post={post} />
    </Stack>
  );
};

StoryBook.propTypes = {};

export default StoryBook;
