import React from "react";
// import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Divider,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import Adornment from "components/TextInputLayout/Adornment";
import AuthUser from "api/authUser";
import { notify } from "util";
import Auth from "api/auth";
import { REACT_APP_CLIENT_AUTH_ENDPOINT } from "config/env";

const useClasses = (theme) => ({
  button: {
    p: theme.spacing(2),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMarginBtm: {
    mb: theme.spacing(3),
  },
  fieldWidth: {
    maxWidth: 400,
  },
});

const signOut = () => {
  // add delay
  setTimeout(() => {
    const api = new Auth();
    api
      .doSignOut()
      .then((res) => {
        console.log(res);
        notify("You must sign in again to incorporate the changes!", "info");
        window.location.href = REACT_APP_CLIENT_AUTH_ENDPOINT;
      })
      .catch((err) => {
        console.log(err);
        notify(err, "error");
      });
  }, 1000);
};

const Settings = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  // const [ojTheme, setOjTheme] = React.useState("light");
  const [showPassword, setShowPassword] = React.useState({
    old: false,
    new: false,
    comp: false,
  });
  const [processing, setProcessing] = React.useState(false);

  // const handleTheme = (event, newOjTheme) => {
  //   if (newOjTheme !== null) {
  //     setOjTheme(newOjTheme);
  //   }
  // };

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClickShowOldPassword = () => {
    setShowPassword({ ...showPassword, old: !showPassword.old });
  };
  const handleClickShowNewPassword = () => {
    setShowPassword({ ...showPassword, new: !showPassword.new });
  };
  const handleClickShowCompPassword = () => {
    setShowPassword({ ...showPassword, comp: !showPassword.comp });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmitClick = () => {
    console.log(form);
    setProcessing(true);
    const api = new AuthUser();
    api
      .changePassword(form)
      .then((res) => {
        console.log(res);
        setProcessing(false);
        notify(res.message, "success");
        signOut();
      })
      .catch((err) => {
        console.log(err);
        notify(err, "error");
        setProcessing(false);
      });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Change Password
      </Typography>
      <Divider />
      <Box sx={classes.itemSpacingMedium}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={classes.fieldWidth}>
              <Typography variant="h4" gutterBottom paddingTop={1}>
                Old Password
              </Typography>
              <TextInputLayout
                // icon="mail"
                placeholder="Old password"
                type={showPassword.old ? "text" : "password"}
                name="oldPassword"
                endAdornment={
                  <Adornment
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                    visible={showPassword.old}
                  />
                }
                onInputChange={onInput}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={classes.fieldWidth}>
              <Typography variant="h4" gutterBottom paddingTop={1}>
                New Password
              </Typography>
              <TextInputLayout
                // icon="mail"
                placeholder="New password"
                type={showPassword.new ? "text" : "password"}
                name="newPassword"
                endAdornment={
                  <Adornment
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    visible={showPassword.new}
                  />
                }
                sx={{ maxWidth: 300 }}
                onInputChange={onInput}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={classes.fieldWidth}>
              <Typography variant="h4" gutterBottom paddingTop={1}>
                Confirm Password
              </Typography>
              <TextInputLayout
                // icon="mail"
                placeholder="Confirm password"
                type={showPassword.comp ? "text" : "password"}
                name="confirmPassword"
                endAdornment={
                  <Adornment
                    onClick={handleClickShowCompPassword}
                    onMouseDown={handleMouseDownPassword}
                    visible={showPassword.comp}
                  />
                }
                onInputChange={onInput}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={classes.itemSpacingLarge}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            onClick={onSubmitClick}
            disabled={processing}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Divider />
      {/* <Box sx={classes.itemSpacingMedium}>
        <Typography variant="h5" gutterBottom>
          Theme Preference
        </Typography>
        <Typography variant="body2" gutterBottom>
          Choose how the OJ looks for you.
        </Typography>
        <ToggleButtonGroup
          value={ojTheme}
          exclusive
          onChange={handleTheme}
          aria-label="oj theme"
          size="large"
        >
          <ToggleButton value="dark" aria-label="dark theme">
            Dark
          </ToggleButton>
          <ToggleButton value="light" aria-label="light theme">
            Light
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> */}
    </Box>
  );
};

Settings.propTypes = {};

export default Settings;
