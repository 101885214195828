import React, { useEffect } from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import Profile from "./components/Profile";
import TotalCalculation from "./components/TotalCalculation";
import JudgeTags from "./components/JudgeTags";
import General from "api/general";
import { notify } from "util";
import ActionAlert from "components/ActionAlert";
import { Helmet } from "react-helmet-async";

const ProfilePage = () => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const handle = urlParams.get("handle");

    const api = new General();
    api
      .getProfile(handle)
      .then((res) => {
        setData(res.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notify(err, "error");
        setLoading(false);
        setError(true);
      });
  }, []);

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return (
      <ActionAlert
        title="Oops!"
        message="Something went wrong."
        action="error"
      />
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {!loading && !error && data && (
        <Grid
          container
          sx={{ gridAutoRows: "50px" }}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1.5 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={4.5}>
            <Profile profile={data} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TotalCalculation totalCalculation={data} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4.5}>
            <JudgeTags onlineJudges={data.onlineJudges} />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4.5}>
          <Submissions
            problemSubmissions={problemSubmissions}
            key={problemSubmissions.problemName}
          />
        </Grid> */}
          {/* <Grid item xs={12} sm={12} md={6} lg={4.5}>
          <SubmissionStatistics />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Accuracy />
        </Grid> */}

          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <SubmissionsByYear />
        </Grid> */}
        </Grid>
      )}
    </Box>
  );
};

export default ProfilePage;
