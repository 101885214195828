import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import IconMapper from "components/IconMapper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { createTheme, ThemeProvider } from "@mui/material";

/**
 * A component that returns the Filled Text Input component
 * @param icon bookmark, mail, location, requirements, phone, delete, upload, user, lock, key, company, description
 * @param placeholder A String for the hint text
 * @param type email, file, date, image, text
 * @param readOnly true or false
 * @param rowsMax integer value (optional)
 * @param value String: default value on the input layout
 * @param name String: name of the input, ie email or password
 * @param onInputChange a function passed that handles the input change event
 */

const customizedTheme = createTheme({
  components: {
    // Name of the component
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        notchedOutline: {
          // Some CSS
          border: "none",
        },
      },
    },
  },
});

const TextInputDropDown = (props, { options }) => {
  //   const classes = useStyles();
  const [value] = useState(props.value ? props.value : "");
  const theme = useTheme();

  return (
    <ThemeProvider theme={customizedTheme}>
      <Paper
        component="form"
        sx={{
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          bgcolor: theme.palette.main.lightAsh2,
          borderRadius: 1,
        }}
        elevation={0}
      >
        <IconMapper name={props.icon} color={theme.palette.main.darkAsh} />
        <FormControl
          fullWidth
          sx={{
            border: "0px solid theme.palette.main.darkAsh",
          }}
        >
          <Select
            name={props.name}
            sx={{
              marginLeft: "10px",
              flex: 1,
              color: theme.palette.main.darkAsh,
              // border: "0px solid theme.palette.main.darkAsh",
            }}
            label={props.placeholder}
            onChange={props.onInputChange}
            defaultValue={value}
            maxRows={10}
          >
            {options &&
              options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Paper>
    </ThemeProvider>
  );
};

TextInputDropDown.propTypes = {
  icon: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onInputChange: PropTypes.func.isRequired,
};

export default TextInputDropDown;
