import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { AlertTitle } from "@mui/material";

export default function ActionAlert({ title, message, action }) {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert severity={action || "info"}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Stack>
  );
}

ActionAlert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  action: PropTypes.oneOf(["info", "success", "warning", "error"]),
};
