// ----------------------------------------------------------------------

export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.main.darkAsh,
        },
      },
    },
  };
}
