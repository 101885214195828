import React from "react";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import PushPinIcon from "@mui/icons-material/PushPin";
import PropTypes from "prop-types";
import { ANNOUNCEMENTS } from "constants/route";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {String} title the header of the pinned box, can be pinned or Noties
 * @param {[Object]} notices the array of notices to be displayed. Each notice is an object with the following properties {title: String}
 */
const NoticeBox = ({ title, notices }) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ py: 2, px: 3 }}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        {title}
      </Typography>

      {notices.map((notice, index) => (
        <Box
          key={index}
          direction="column"
          sx={{
            pt: index == 0 ? 1 : 0,
            pb: index !== notices.length - 1 ? 1 : 0,
          }}
        >
          <div style={{ display: "flex" }}>
            <PushPinIcon sx={{ mr: 1 }} />
            <Typography variant="h4">{notice.title}</Typography>
          </div>
          {index !== notices.length - 1 && <Divider sx={{ my: 1 }} />}
        </Box>
      ))}

      {notices.length === 0 && (
        <Typography variant="body1">No content to display right now!</Typography>
      )}
      <Typography sx={{ textAlign: "center", mt: 2 }}>
        <Button
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
          onClick={() => navigate(ANNOUNCEMENTS)}
        >
          View All
        </Button>
      </Typography>
    </Card>
  );
};

NoticeBox.propTypes = {
  title: PropTypes.string,
  notices: PropTypes.array.isRequired,
};

export default NoticeBox;
