import React, { useEffect } from "react";
// import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Divider,
  Typography,
  useTheme,
  Grid,
  Skeleton,
} from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import AuthUser from "api/authUser";
import { notify } from "util";
import { ONLINE_JUDGES } from "constants/app";

const useClasses = (theme) => ({
  button: {
    p: theme.spacing(2),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMarginBtm: {
    mb: theme.spacing(3),
  },
});

const OnlineJudges = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [form, setForm] = React.useState(null);
  const [currentJudges, setCurrentJudges] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [processing, setProcessing] = React.useState(false);

  useEffect(() => {
    const api = new AuthUser();
    api
      .getOnlineJudges()
      .then(({ message }) => {
        console.log(message);
        setCurrentJudges(message.onlineJudges);
        setLoading(false);
      })
      .catch((err) => {
        notify(err, "error");
        setLoading(false);
      });
  }, []);

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitClick = () => {
    const api = new AuthUser();
    const onlineJudges = {
      ...form,
      ...currentJudges,
    };
    setProcessing(true);

    api
      .putOnlineJudges({
        onlineJudges: onlineJudges,
      })
      .then((res) => {
        console.log(res);
        setProcessing(false);
        notify(res.message, "success");
      })
      .catch((err) => {
        notify(err, "error");
        setProcessing(false);
      });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Online Judges
      </Typography>
      <Typography sx={classes.itemMarginBtm} variant="body2" gutterBottom>
        You can add your handles of other online judges to share it on our
        platform
      </Typography>

      <Divider />
      <Box sx={classes.itemSpacingMedium}>
        <Grid container spacing={2}>
          {ONLINE_JUDGES.map((item, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <Box>
                <Typography variant="h4" gutterBottom paddingTop={1}>
                  {item}
                </Typography>
                {loading ? (
                  <Skeleton variant="text" height={30} />
                ) : (
                  <TextInputLayout
                    placeholder={"Enter " + item + " username"}
                    type="text"
                    name={item}
                    value={currentJudges ? currentJudges[item] : ""}
                    onInputChange={onInput}
                  />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={classes.itemSpacingLarge}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            onClick={onSubmitClick}
            disabled={processing}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

OnlineJudges.propTypes = {};

export default OnlineJudges;
