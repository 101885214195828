import { useTheme } from "@emotion/react";
import { Box, Chip, Divider, Grid, Button, Snackbar } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ReactMarkdown from "react-markdown";
import { StyledTableCell } from "../Utils";
import randomColor from "randomcolor";
import IconMapper from "components/IconMapper";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import General from "api/general";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import LinearLoadingBar from "components/LinearLoadingBar";

const useClasses = (theme) => ({
  titleSpacing: {
    mt: theme.spacing(2),
  },
  tableMargin: {
    pt: theme.spacing(3),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  information: {
    p: "0px 15px 0 10px",
  },
  informationContainer: {
    display: "flex",
  },
  itemSpacingLarge: {
    pt: theme.spacing(2),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMargin: {
    pb: theme.spacing(2),
  },
  button: {
    px: theme.spacing(5),
  },
  pre: {
    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
  },
});

const SampleIO = ({ sampleTestcases, classes }) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState("none");
  const [visibleOutput, setVisibleOutput] = useState("none");

  const mouseEnter = () => {
    setVisible("");
  };

  const mouseLeave = () => {
    setVisible("none");
  };

  const mouseEnterOutput = () => {
    setVisibleOutput("");
  };

  const mouseLeaveOutput = () => {
    setVisibleOutput("none");
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <TableContainer sx={classes.itemSpacingLarge}>
      <TableHead>
        <TableRow>
          <StyledTableCell align="left">Input</StyledTableCell>
          <StyledTableCell align="left">Output</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sampleTestcases &&
          sampleTestcases.map((sample, index) => (
            <TableRow key={index}>
              <StyledTableCell
                align="left"
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                sx={{
                  verticalAlign: "top",
                }}
              >
                <pre style={classes.pre}>{sample.input}</pre>
                <Grid container justifyContent="flex-end">
                  <Button
                    startIcon={
                      <ContentCopyOutlinedIcon style={{ marginLeft: "25%" }} />
                    }
                    onClick={() => {
                      handleClick();
                      navigator.clipboard.writeText(sample.input);
                    }}
                    variant="text"
                    sx={{
                      display: visible,
                      maxWidth: "35px",
                      maxHeight: "30px",
                      minWidth: "35px",
                      minHeight: "30px",
                      fontSize: "0.7rem",
                    }}
                  ></Button>
                  <Snackbar
                    message="Copied to clibboard"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={1000}
                    onClose={() => setOpen(false)}
                    open={open}
                  />
                </Grid>
              </StyledTableCell>
              <StyledTableCell
                align="left"
                onMouseEnter={mouseEnterOutput}
                onMouseLeave={mouseLeaveOutput}
                sx={{
                  verticalAlign: "top",
                }}
              >
                <Grid container style={{ marginTop: "-1%" }}>
                  <Grid item xs={10}>
                    <pre style={classes.pre}>{sample.output}</pre>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      startIcon={
                        <ContentCopyOutlinedIcon
                          style={{ marginLeft: "25%" }}
                        />
                      }
                      onClick={() => {
                        handleClick();
                        navigator.clipboard.writeText(sample.output);
                      }}
                      variant="text"
                      sx={{
                        display: visibleOutput,
                        maxWidth: "35px",
                        maxHeight: "30px",
                        minWidth: "35px",
                        minHeight: "30px",
                        fontSize: "0.7rem",
                      }}
                    ></Button>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </TableRow>
          ))}
      </TableBody>
    </TableContainer>
  );
};

export default function ProblemPage() {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [problem, setProblem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const urlParts = window.location.href.split("/");
      const name = urlParts[urlParts.length - 1];

      const api = new General();
      api
        .getProblem(name)
        .then(({ message }) => {
          console.log("res", message);
          setLoading(false);
          setProblem(message);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }, []);

  if (loading) {
    return <LinearLoadingBar />;
  }

  return (
    <Box>
      <Typography sx={classes.titleSpacing} variant="h3" color="primary">
        {problem && problem.title}
      </Typography>
      <Box sx={classes.itemSpacingMedium}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box sx={classes.itemMargin} direction="row">
              {problem &&
                problem.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    sx={{
                      mr: 1,
                      mb: 1,
                      backgroundColor: randomColor({
                        seed: tag,
                        luminosity: "light",
                        format: "hsla",
                      }),
                    }}
                  />
                ))}
            </Box>
            <Box sx={classes.informationContainer}>
              <IconMapper name="user" />
              <Typography sx={classes.information} variant="subtitle2">
                {(problem && problem.author) || "Anonymous"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconMapper name="time" />
              <Typography sx={classes.information} variant="subtitle2">
                {problem?.limits?.time || "N/A"}
                {" s"}
              </Typography>
            </Box>
            <Box sx={classes.itemMargin}></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconMapper name="memory" />
              <Typography sx={classes.information} variant="subtitle2">
                {problem?.limits?.memory || "N/A"}
                {" MB"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={classes.itemSpacingLarge}>
        <ReactMarkdown>
          {(problem && problem.description) || "N/A"}
        </ReactMarkdown>
      </Box>
      {problem && problem.input && problem.output && (
        <>
          <Box sx={classes.itemSpacingLarge}>
            <Typography variant="h3" color="primary">
              Input
            </Typography>
          </Box>
          <Box sx={classes.itemSpacingSmall}>
            <ReactMarkdown>{(problem && problem.input) || "N/A"}</ReactMarkdown>
          </Box>
          <Box sx={classes.itemSpacingLarge}>
            <Typography variant="h3" color="primary">
              Output
            </Typography>
          </Box>
          <Box sx={classes.itemSpacingSmall}>
            <ReactMarkdown>
              {(problem && problem.output) || "N/A"}
            </ReactMarkdown>
          </Box>
        </>
      )}

      {problem && problem.sampleTestcases && (
        <Box sx={classes.itemSpacingLarge}>
          <Typography variant="h3" color="primary">
            Sample I/O
          </Typography>

          <SampleIO
            sampleTestcases={problem.sampleTestcases || []}
            classes={classes}
          />
        </Box>
      )}

      <Box sx={(classes.itemSpacingSmall, classes.tableMargin)}></Box>

      {problem?.notes && (
        <>
          <Box sx={classes.itemSpacingLarge}>
            <Typography variant="h3" color="primary">
              Note
            </Typography>
          </Box>
          <Box sx={classes.itemSpacingSmall}>
            <ReactMarkdown>{problem?.notes || "N/A"}</ReactMarkdown>
          </Box>
        </>
      )}
    </Box>
  );
}

ProblemPage.propTypes = {};

SampleIO.propTypes = {
  sampleTestcases: PropTypes.array,
  classes: PropTypes.object,
};
