// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".split {\n  display: flex;\n  flex-direction: row;\n}\n\n.gutter {\n  background-color: var(--color-lightAsh);\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n.gutter:hover {\n  transition-delay: 0.1s;\n  background-color: var(--color-darkAsh);\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n\n.gutter.gutter-horizontal {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  cursor: col-resize;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProblemPage/splitpane.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;EACvC,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;EACE,sBAAsB;EACtB,sCAAsC;EACtC,4BAA4B;EAC5B,wBAAwB;AAC1B;;AAEA;EACE,yDAA2K;EAC3K,kBAAkB;AACpB","sourcesContent":[".split {\n  display: flex;\n  flex-direction: row;\n}\n\n.gutter {\n  background-color: var(--color-lightAsh);\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n.gutter:hover {\n  transition-delay: 0.1s;\n  background-color: var(--color-darkAsh);\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n\n.gutter.gutter-horizontal {\n  background-image: url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==\");\n  cursor: col-resize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
