import { Box, Typography, useTheme, Grid } from "@mui/material";
import { MHidden } from "components/@material-extend";
import IconMapper from "components/IconMapper";
import sponsorLogo from "assets/sponsorLogo.svg";
import {
  WINDOW_MARGIN,
  WINDOW_MARGIN_MD,
  WINDOW_MARGIN_SM,
} from "constants/dimens";
// import IconMapper from "components/IconMapper";

const footerContent = {
  location: `141 & 142, Love Road, Tejgaon Industrial Area, Dhaka-1208.`,
  body: `AUST OJ  A Product of AUST Programming and Informatics Club Server Sponsor: GOTMYHOST`,
  social: [
    {
      name: "facebook",
      link: "https://www.facebook.com/austoj/",
    },
    {
      name: "email",
      link: "mailto:admin@austoj.com/",
    },
    {
      name: "web",
      link: "https://austpic.com",
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/aust_programming_club/",
    },
    {
      name: "youtube",
      link: "https://www.youtube.com/channel/UCMkBZGTswDhp8QA49diClPg/",
    },
    
    {
      name: "github",
      link: "https://www.github.com/austpic/",
    },
    
  ],
};

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 5,
        mt: "auto",

        backgroundColor: theme.palette.main.white,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          p: WINDOW_MARGIN,
          "@media (max-width: 1500px)": {
            p: WINDOW_MARGIN_MD,
          },

          [theme.breakpoints.down("sm")]: {
            p: WINDOW_MARGIN_SM,
          },
        }}
      >
        <Grid item xs={4}>
          <Box display="flex">
            <IconMapper name="location" color={theme.palette.main.red} />
            <Typography
              variant="body1"
              sx={{
                px: 1,
              }}
            >
              {footerContent.location}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Typography variant="body1">
            <b>AUST OJ</b>, A Product of{" "}
            <a
              href="https://www.facebook.com/AUSTPIC"
              target="_blank"
              rel="noreferrer"
              style={{
                // remove underline
                textDecoration: "none",
                color: theme.palette.main.black,
              }}
            >
              AUST Programming and Informatics Club
            </a>

            <br/>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                
              }}
            >
              <i
                style={{
                  color: theme.palette.main.green,
                }}
              >
                Server Sponsored By:{" "}
              </i>
              <i>
                <a
                  href="https://www.gotmyhost.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    // remove underline
                    textDecoration: "none",
                    color: theme.palette.main.red,
                  }}
                >
                  <img src={sponsorLogo}  alt="GOTMYHOST" width="140"/>
                </a>
              </i>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box display="flex">
            <MHidden width={"lgDown"}>
              <Box flexGrow={1} />
            </MHidden>

            {footerContent.social.map((item, index) => (
              <Box
                key={index}
                sx={{ p: 0.5, cursor: "pointer" }}
                onClick={() => window.open(item.link, "_blank")}
              >
                <IconMapper name={item.name} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
