import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

import { HOME } from "constants/route";
import Logo from "./Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={HOME}>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
