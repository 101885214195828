import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import TextInputDropDown from "components/TextInputDropDown";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 500,
    px: theme.spacing(3),
    pb: theme.spacing(2),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: theme.customShadows.primary,
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const ClarificationBox = ({ onInput, onSubmitClick }) => {
  const theme = useTheme();
  const classes = useClasses(theme);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Paper sx={classes.paper}>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "left" }} variant="h3">
            Request Clarification
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            placeholder="Enter title"
            type="text"
            name="title"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputDropDown
            placeholder="Select problem"
            type="text"
            name="problem"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacing}>
          <TextInputLayout
            placeholder="Enter query"
            type="text"
            name="query"
            onInputChange={onInput}
          />
        </Box>
        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </Box>
      </Paper>

      <Box sx={{ padding: theme.spacing(5) }} />
    </Box>
  );
};

ClarificationBox.propTypes = {
  onInput: PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
};

export default ClarificationBox;
