// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JudgeTags_judgeTagsContainer__dCfiX {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n  height: 100%;\n}\n\n.JudgeTags_judgeTags__fK2v8 {\n  color: var(--color-blackShadeFont);\n  font-weight: bold;\n  padding-bottom: 38px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/components/JudgeTags.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kCAAkC;EAClC,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".judgeTagsContainer {\n  background-color: var(--color-white);\n  box-shadow: var(--shadow-card);\n  border-radius: 10px;\n  height: 100%;\n}\n\n.judgeTags {\n  color: var(--color-blackShadeFont);\n  font-weight: bold;\n  padding-bottom: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"judgeTagsContainer": "JudgeTags_judgeTagsContainer__dCfiX",
	"judgeTags": "JudgeTags_judgeTags__fK2v8"
};
export default ___CSS_LOADER_EXPORT___;
