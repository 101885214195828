import { Avatar, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import announcementIcon from "assets/announcement.svg";

const Announcement = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  minHeight: theme.spacing(9),
  display: "grid",
  alignItems: "center",
  textAlign: "left",
  cursor: "pointer",
  boxShadow: theme.customShadows.primary,
  height: "90px",
}));

/**
 *
 * @param {String} title  header name
 * @param {String} iconName  [optional] the name of the icon to be displayed
 * @param {String} to the route to be navigated to
 */
const SingleBox = ({ title, to }) => {
  const navigate = useNavigate();
  return (
    <Announcement onClick={() => navigate(to)}>
      <Box
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
        }}
      >
        <Avatar
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            objectFit: "contain",
          }}
          variant="square"
          src={announcementIcon}
          srcSet={announcementIcon}
        />

        <Box sx={{ pr: 3 }} />
        <Typography
          variant="h3"
          sx={{
            alignSelf: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Announcement>
  );
};

SingleBox.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default SingleBox;
