import React from "react";
import { Avatar, Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./Profile.module.css";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import IconMapper from "components/IconMapper";
import Stack from "@mui/material/Stack";
import { SETTINGS } from "constants/route";
import { useNavigate } from "react-router-dom";
import randomColor from "randomcolor";
import { getCurrentUser } from "config/auth";

const Profile = ({ profile }) => {
  const {
    avatar,
    handle,
    fullName,
    socialAccounts,
    roles,
    createdAt,
    institution,
  } = profile;

  const navigate = useNavigate();
  return (
    <Box className={styles.infoContainer}>
      <Box sx={{ padding: "0 29px 0 29px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item>
            <Box
              sx={{
                display: "inline-grid",
                alignContent: "center",
              }}
            >
              <Avatar
                sx={{
                  width: 130,
                  height: 130,
                  borderRadius: 1,
                  objectFit: "contain",
                }}
                variant="square"
                src={avatar}
                srcSet={avatar}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ pl: 1 }}>
              <Typography variant="h3" gutterBottom>
                {fullName}
              </Typography>
              <Typography variant="h4" gutterBottom>
                {handle}
              </Typography>
              <Box
                className={styles.social}
                sx={{
                  display: "flex",
                }}
              >
                {socialAccounts.map((account, index) => {
                  const { social, userName } = account;
                  if (userName) {
                    return (
                      <Stack
                        direction="column"
                        key={index}
                        sx={{
                          pr: 1,
                        }}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(userName, "_blank");
                          }}
                        >
                          <IconMapper name={social.toLowerCase()} size="30px" />
                        </div>
                      </Stack>
                    );
                  }
                  return <div key={index}></div>;
                })}
              </Box>
            </Box>
          </Grid>
          <Box flexGrow={1} />

          <Grid item>
            {getCurrentUser()?.profile?.handle === handle && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() => {
                  navigate(SETTINGS);
                }}
              >
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.rank}>
        <Stack direction="row" spacing={1}>
          {/* need to set the color to the chip. every chip will have their different color */}
          {roles.map((role, index) => (
            <Chip
              label={role}
              sx={{
                borderRadius: "4px",
                backgroundColor: randomColor({
                  seed: role,
                  luminosity: "light",
                  format: "hsla",
                }),
              }}
              key={index}
            />
          ))}
        </Stack>
      </Box>
      <Divider className={styles.divider} />
      <Box className={styles.userInfo}>
        <Box className={styles.flexDisplay}>
          <IconMapper name={"calender"} />
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            Joined on {new Date(createdAt).toLocaleDateString()}
          </Typography>
        </Box>
        <Box className={styles.flexDisplay}>
          <IconMapper name={"institution"} />
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {institution || "N/A"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default Profile;
