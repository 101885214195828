import { REACT_APP_CLIENT_AUTH_ENDPOINT } from "config/env";

// auth routes
export const SIGN_UP = `${REACT_APP_CLIENT_AUTH_ENDPOINT}/sign-up`;
export const SIGN_IN = `${REACT_APP_CLIENT_AUTH_ENDPOINT}/sign-in`;
export const FORGOT_PASSWORD = `${REACT_APP_CLIENT_AUTH_ENDPOINT}/forgot-password`;
export const RESET_PASSWORD = `${REACT_APP_CLIENT_AUTH_ENDPOINT}/reset-password`;

export const PRIVATE_PAGE = "/private";
export const NOT_FOUND = "/404";
export const UNAUTHORIZED = "/401";

// navbar routes
export const HOME = "/";
export const PROBLEMS = "/problems";
export const PROBLEM = "/problem/:name";
export const LEADERBOARD = "/leaderboard";
export const CONTEST = "/contests";
export const SUBMISSIONS = "/submissions";

// pages
export const ANNOUNCEMENTS = "/announcements";
export const VERIFICATION = "/verification";
export const SETTINGS = "/settings";
export const PROFILE = `/profile`;
