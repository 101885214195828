import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ActiveSolver from "components/ActiveSolver";
import React, { useEffect } from "react";
import ProblemsTable from "./ProblemsTable";
import { useTheme } from "@emotion/react";
import TextInputLayout from "components/TextInputLayout";
import StyledChip from "components/StyledChip";
import FilterMenu from "components/FilterMenu";
//import { upperCaseFirst } from "upper-case-first";
import { getCurrentUser } from "config/auth";
import General from "api/general";
import Constants from "api/constants";
import { Helmet } from "react-helmet-async";
import randomColor from "randomcolor";

const HeaderBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  textAlign: "left",
  boxShadow: theme.customShadows.primary,
}));

export default function ProblemsPage() {
  const theme = useTheme();
  const [chipData, setChipData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [user, setUser] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [filterItems, setFilterItems] = React.useState([]);

  useEffect(() => {
    setUser(getCurrentUser());
    const api = new General();
    const constanstApi = new Constants();
    constanstApi
      .getConstants("difficulties")
      .then((res) => {
        setFilterItems(res.message.difficulty);
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .listProblems()
      .then((res) => {
        console.log(res);
        setRows(res.message);
        setFilterData(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onInputChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchClick = () => {
    
    const filteredData = rows.filter((row) => {
      return row.title.toLowerCase().includes(search.toLowerCase());
    });
    setFilterData(filteredData);
  };
 

  const handleChipChange = (value) => {
    
    if (value.length) {

    const temp=[];
    for (var i = 0; i < value.length; i++)
    {
        const x = rows.filter((row) => {
          return row.difficulty.toLowerCase().includes(value[i]);
        });
        if(x.length)
          temp.push(x[0]);
    }
      setFilterData(temp);
     
    } else {
      setFilterData(rows);
    
    }
  };

  const handleChipDelete = (chipToDeleteIndex) => () => {
    // filter out the chip that was clicked
    const chipToDelete = chipData[chipToDeleteIndex];
    const updatedChips = chipData.filter((chip) => chip !== chipToDelete);
    setChipData(updatedChips);
    

    // update the filtered data
    console.log(updatedChips);

    if (updatedChips.length) {
      const temp=[];
      for (var i = 0; i < updatedChips.length; i++)
      {
          const x = rows.filter((row) => {
            return row.difficulty.toLowerCase().includes(updatedChips[i])
          });
          if(x.length)
            temp.push(x[0]);
    }
      setFilterData(temp);
    } else {
      setFilterData(rows);
    }

    console.log(updatedChips);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Problems</title>
      </Helmet>
      {/* header box */}
      <HeaderBox sx={{ pl: 3 }}>
        <Stack direction="row" spacing={2}>
          <TextInputLayout
            placeholder="Enter title"
            type="search"
            name="title"
            onInputChange={onInputChange}
            padding={1}
            onActionClick={handleSearchClick}
            onButtonPress={handleSearchClick}
          />
          <FilterMenu
            items={filterItems}
            placeholder="Select Difficulty"
            name="Difficulty"
            width={180}
            selectedList={chipData}
            setSelectedList={setChipData}
            onInputChange={(e)=>handleChipChange(e.target.value)}
            isMultiple
          />
        </Stack>
      </HeaderBox>
      <Box sx={{ mt: 2 }} />
      <Grid container spacing={2}>
        {/* left content */}
        <Grid item xs={12} lg={9} md={12} sm={12}>
          <Box
            sx={{
              borderRadius: 1,
              boxShadow: theme.customShadows.primary,
              padding: "15px 30px 25px 30px",
              backgroundColor: theme.palette.main.white,
            }}
          >
            <Typography variant="h3" gutterBottom paddingTop={1}>
              Practice Problems
            </Typography>

            <Box sx={{ mt: 2 }}>
              {chipData.map((data, index) => {
                return (
                  <StyledChip
                    sx={{
                      backgroundColor: randomColor({
                        luminosity: "light",
                        format: "alpha",
                      }),
                    }}
                    key={index}
                    label={data}
                    onDelete={handleChipDelete(index)}
                    size="small"
                  />
                );
              })}
            </Box>

            <ProblemsTable filter={chipData} data={filterData} user={user} />
          </Box>
        </Grid>

        {/* right content */}
        <Grid item xs={12} lg={3} md={12} sm={12}>
          {/* <UpcomingBox title="Upcoming Contests" items={contests} /> */}
          {/* <Box sx={{ mt: 2 }} /> */}
          <ActiveSolver />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ProblemsPage.propTypes = {};
