import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";
import { Paper, InputBase, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import IconMapper from "components/IconMapper";
import SearchIcon from "@mui/icons-material/Search";

/**
 * A component that returns the Filled Text Input component
 * @param icon bookmark, mail, location, requirements, phone, delete, upload, user, lock, key, company, description
 * @param placeholder A String for the hint text
 * @param type email, file, date, image, text, search
 * @param readOnly true or false
 * @param rowsMax integer value (optional)
 * @param value String: default value on the input layout
 * @param name String: name of the input, ie email or password
 * @param onInputChange a function passed that handles the input change event
 * @param padding a value passed in to set the padding of the box
 * @param onActionClick a function which is triggered when the search button is clicked. Only viable if type = "search"
 */

const TextInputLayout = ({
  icon,
  placeholder,
  type,
  readOnly,
  disabled,
  rowsMax,
  value,
  name,
  width,
  height,
  padding,
  endAdornment,
  onInputChange,
  onActionClick,
  onButtonPress,
}) => {
  //   const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper
      component="form"
      sx={{
        padding: icon ? "2px 20px" : "",
        display: "flex",
        alignItems: "center",
        bgcolor: theme.palette.main.lightAsh,
        borderRadius: 1,
        width: width && theme.spacing(width),
        height: height ? height : "auto",
        pr: endAdornment && 1,
      }}
      elevation={0}
    >
      {icon && <IconMapper name={icon} color={theme.palette.main.darkAsh} />}

      <InputBase
        name={name}
        sx={{
          marginLeft: "10px",
          flex: 1,
          color: theme.palette.main.blackShade,
          padding: padding ? padding : "10px",
        }}
        endAdornment={endAdornment && endAdornment}
        placeholder={placeholder}
        fullWidth={true}
        onChange={onInputChange}
        type={type}
        defaultValue={value ? value : ""}
        maxRows={rowsMax ? rowsMax : 10}
        readOnly={readOnly ? readOnly : false}
        disabled={disabled ? disabled : false}
        style={{
          minWidth: 50,
        }}
        inputProps={{
          style: {
            // ! cant you use theme.customShadows.primary here? @matcovic
            WebkitBoxShadow: "0 0 0 1000px var(--color-lightAsh) inset",
          },
        }}
        onKeyPress={(event) => {
        if (event.key == 'Enter')
        {
            event.preventDefault();
            onButtonPress();
        }
    }}
      />
      {type === "search" && (
        <Button
          sx={{
            height: "100%",
            backgroundColor: theme.palette.main.green,
            color: theme.palette.main.white,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            "&:hover": {
              backgroundColor: theme.palette.main.green,
            },
          }}
          onClick={() => onActionClick()}
        >
          <SearchIcon />
        </Button>
      )}
    </Paper>
  );
};

TextInputLayout.propTypes = {
  icon: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  endAdornment: PropTypes.element,
  onInputChange: PropTypes.func.isRequired,
  padding: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  rowsMax: PropTypes.number,
  onActionClick: PropTypes.func,
  onButtonPress:PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TextInputLayout;
