import React from "react";
import PropTypes from "prop-types";

// mui
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import FilterMenu from "components/FilterMenu";
import { editorCodeThemes, editorFontSizes } from "constants/app";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 500,
    px: theme.spacing(6),
    pb: theme.spacing(4),
    mx: "auto",
    mt: "10vh",
    boxShadow: theme.customShadows.primary,
  },
  button: {
    mt: theme.spacing(1),
  },
  itemSpacing: {
    pt: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    pt: theme.spacing(3),
  },
});

const EditorSettings = ({
  open,
  handleClose,
  editorTheme,
  setTheme,
  fontSize,
  setFontSize,
}) => {
  const theme = useTheme();
  const classes = useClasses(theme);

  const handleFilterChange = (event) => {
    console.log("name: ", event.target.name);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={classes.paper}>
        <Box sx={classes.itemSpacingMedium}>
          <Typography sx={{ textAlign: "center" }} variant="h3">
            Editor Settings
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Divider />
        </Box>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "left" }} variant="h5">
            Theme
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1">
                Change the default theme of the editor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterMenu
                name="Theme"
                width={120}
                item={editorTheme}
                items={editorCodeThemes}
                setItem={setTheme}
                onInputChange={handleFilterChange}
                defaultReplaceValue={editorTheme}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "left" }} variant="h5">
            Font Size
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1">
                Change the default font size of the editor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterMenu
                name="fontSize"
                items={editorFontSizes}
                width={120}
                item={fontSize}
                setItem={setFontSize}
                onInputChange={handleFilterChange}
                defaultReplaceValue={fontSize}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.itemSpacingMedium}>
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

EditorSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  editorTheme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  fontSize: PropTypes.string.isRequired,
  setFontSize: PropTypes.func.isRequired,
};

export default EditorSettings;
