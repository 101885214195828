import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import EditorialPage from "./EditorialPage";
import ProblemPage from "./ProblemPage";
import SubmissionsPage from "./SubmissionsPage";

const tabs = [
  <ProblemPage key={0} />,
  <SubmissionsPage key={1} />,
  <EditorialPage key={2} />,
];

const PanelLeft = ({ selectedTab }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.main.white,
        px: 2,
        py: 1,
      }}
    >
      {tabs[selectedTab]}
    </Box>
  );
};

PanelLeft.propTypes = {
  selectedTab: PropTypes.number.isRequired,
};

export default PanelLeft;
