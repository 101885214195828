import { useTheme } from "@emotion/react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import ReactMarkdown from "react-markdown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LinkedTypography from "components/LinkedTypography";
import PropTypes from "prop-types";
import General from "api/general";

const EditorialContent = ({ editorial, theme }) => {
  const { description, attachments } = editorial;
  return (
    <Box
      sx={{
        minHeight: "75vh",
      }}
    >
      <Box>
        <Box sx={{ py: theme.spacing(3) }}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Box>
      </Box>

      {attachments.length > 0 && (
        <>
          <Divider />
          <Box sx={{ py: theme.spacing(3) }}>
            <Typography
              sx={{ mt: theme.spacing(2) }}
              variant="h3"
              color="primary"
            >
              Attachments
            </Typography>
          </Box>
          <Box sx={{ py: theme.spacing(1) }}>
            {attachments.map((attachment, index) => (
              <Box key={index} sx={{ display: "flex" }}>
                <AttachFileIcon />
                <LinkedTypography
                  to={attachment.url}
                  text={attachment.name}
                  sx={{ p: "0px 15px 0 10px" }}
                  variant="subtitle2"
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

EditorialContent.propTypes = {
  editorial: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default function EditorialPage() {
  const theme = useTheme();
  const [islocked, setIsLocked] = useState(true);
  const [editorial, setEditorial] = useState(null);
  const [error, setError] = useState(null);

  const handleClick = () => {
    try {
      const urlParts = window.location.href.split("/");
      const name = urlParts[urlParts.length - 1];

      const api = new General();
      api
        .getEditorial(name)
        .then((res) => {
          console.log("res", res);
          setEditorial(res.message.editorial);
          setIsLocked(false);
        })
        .catch((err) => {
          console.log(err);

          setError("You must log in to view this page!");
        });
    } catch (e) {
      console.trace(e);
    }
  };

  if (!islocked) {
    return <EditorialContent editorial={editorial} theme={theme} />;
  }

  if (error) {
    return (
      <Typography variant="h3" color="primary">
        {error}
      </Typography>
    );
  }

  if (!editorial?.description) {
    return (
      <Typography variant="h4" color="primary">
        No editorials available yet. Keep trying yourself! 😅
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        textAlign: "center",
        minHeight: "75vh",
        py: theme.spacing(3),
      }}
    >
      <Typography sx={{ mt: theme.spacing(2) }} variant="h3" color="primary">
        Ready to unlock?
      </Typography>
      <Typography sx={{ py: theme.spacing(2) }} color="primary">
        We encourage you to practice <br /> solving the problem <br />
        yourself before looking at the editorial
      </Typography>
      <Button
        onClick={handleClick}
        sx={{ px: theme.spacing(5) }}
        variant="contained"
        color="secondary"
      >
        Unlock
      </Button>
    </Box>
  );
}
