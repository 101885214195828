// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (max-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = "Roboto, sans-serif";
const FONT_WEIGHTS = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 700,
  bold: 800,
};

const SCALE_SIZE = 0.85;

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightLight: FONT_WEIGHTS.light,
  fontWeightRegular: FONT_WEIGHTS.regular,
  fontWeightMedium: FONT_WEIGHTS.medium,
  fontWeightSemiBold: FONT_WEIGHTS.semiBold,
  fontWeightBold: FONT_WEIGHTS.bold,
  h1: {
    fontWeight: FONT_WEIGHTS.bold,
    lineHeight: 80 / 64,
    fontSize: pxToRem(35 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 25,
      md: 30 * SCALE_SIZE,
      lg: 35 * SCALE_SIZE,
    }),
  },
  h2: {
    fontWeight: FONT_WEIGHTS.semiBold,
    lineHeight: 64 / 48,
    fontSize: pxToRem(25 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 20,
      md: 25 * SCALE_SIZE,
      lg: 25 * SCALE_SIZE,
    }),
  },
  h3: {
    fontWeight: FONT_WEIGHTS.bold,
    lineHeight: 1.5,
    fontSize: pxToRem(18 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 16,
      md: 18 * SCALE_SIZE,
      lg: 18 * SCALE_SIZE,
    }),
  },
  h4: {
    fontWeight: FONT_WEIGHTS.medium,
    lineHeight: 1.5,
    fontSize: pxToRem(17 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 15,
      md: 17 * SCALE_SIZE,
      lg: 17 * SCALE_SIZE,
    }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 18,
      md: 18 * SCALE_SIZE,
      lg: 18 * SCALE_SIZE,
    }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 17,
      md: 17 * SCALE_SIZE,
      lg: 17 * SCALE_SIZE,
    }),
  },
  subtitle1: {
    fontWeight: FONT_WEIGHTS.regular,
    lineHeight: 1.5,
    fontSize: pxToRem(19 * SCALE_SIZE),
    ...responsiveFontSizes({
      sm: 13,
      md: 18 * SCALE_SIZE,
      lg: 19 * SCALE_SIZE,
    }),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14 * SCALE_SIZE),
  },
  body1: {
    fontWeight: FONT_WEIGHTS.regular,
    lineHeight: 1.5,
    fontSize: pxToRem(17 * SCALE_SIZE),
  },
  body2: {
    fontWeight: FONT_WEIGHTS.regular,
    lineHeight: 22 / 14,
    fontSize: pxToRem(17 * SCALE_SIZE),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12 * SCALE_SIZE),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12 * SCALE_SIZE),
    letterSpacing: 1.1,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: FONT_WEIGHTS.medium,
    lineHeight: 24 / 14,
    fontSize: pxToRem(17 * SCALE_SIZE),
    textTransform: "capitalize",
    ...responsiveFontSizes({
      sm: 13,
      md: 15 * SCALE_SIZE,
      lg: 17 * SCALE_SIZE,
    }),
  },
};

export default typography;
