import PropTypes from "prop-types";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React from "react";
// icons
import LinkedTypography from "components/LinkedTypography";
import { useTheme } from "@emotion/react";
import AccordionUserDropDown from "../AccordionUserDropDown";
import { useNavigate } from "react-router-dom";

const HamburgerMenu = ({ isMenuOpen, handleCloseNavMenu, HEADERS, user }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Menu
      id="menu-appbar"
      anchorEl={isMenuOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(isMenuOpen)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block" },
      }}
    >
      {HEADERS.navbar.map((item, index) => (
        <MenuItem
          sx={{ justifyContent: "center" }}
          key={index}
          onClick={() => {
            navigate(item.to);
            handleCloseNavMenu();
          }}
        >
          <Box
            key={index}
            sx={{ mx: theme.spacing(4), my: theme.spacing(0.2) }}
          >
            <LinkedTypography
              to={item.to}
              variant="h4"
              text={item.text}
              color="secondary"
            />
          </Box>
        </MenuItem>
      ))}
      {user && user.isAuthenticated && (
        <MenuItem
          sx={{ justifyContent: "center", display: "contents" }}
          key={2}
          disableRipple
        >
          <AccordionUserDropDown />
        </MenuItem>
      )}
      {user && !user.isAuthenticated && (
        <MenuItem
          sx={{ justifyContent: "center", padding: 0 }}
          onClick={handleCloseNavMenu}
        >
          <Button
            fullWidth
            size="medium"
            variant="primary"
            color="dark"
            onClick={() => (window.location.href = HEADERS.button.signin.to)}
            sx={{
              color: theme.palette.main.white,
              px: theme.spacing(6),
              py: theme.spacing(2),
              borderRadius: 0,
            }}
          >
            {HEADERS.button.signin.text}
          </Button>
        </MenuItem>
      )}
    </Menu>
  );
};

HamburgerMenu.propTypes = {
  isMenuOpen: PropTypes.func,
  HEADERS: PropTypes.object.isRequired,
  handleCloseNavMenu: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default HamburgerMenu;
