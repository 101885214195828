import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { PropTypes } from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.main.lightAsh,
  borderRadius: 10,
  // style root
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderWidth: "0px",
    },
    "&:hover fieldset": {
      borderWidth: "0px",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "0px",
    },
  },
}));

/**
 * It works for both multiple and single select
 * Single Select: send props: item and setItem
 * Multiple Select: send props: selectedList and setSelectedList and isMultiple
 * @returns {Node}
 */
export default function FilterMenu({
  items,
  placeholder,
  name,
  width,
  selectedList,
  setSelectedList,
  item,
  setItem,
  onInputChange,
  isMultiple,
  defaultReplaceValue,
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    onInputChange && onInputChange(event, value);

    if (!isMultiple) {
      setItem(value);
    } else {
      setSelectedList(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  return (
    <div>
      <StyledFormControl
        sx={{
          width: width ? width : 250,
          ['@media (max-width:550px)']: { 
            width: '100%'
          }
          
        }}
      >
        <Select
          multiple={isMultiple ? true : false}
          name={name}
          displayEmpty
          value={isMultiple ? selectedList : item}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography variant="h4" color="secondary">
                  {name}
                </Typography>
              );
            }

            return (
              <Typography variant="h4" color="secondary">
                {defaultReplaceValue
                  ? defaultReplaceValue
                  : isMultiple
                  ? selectedList[0]
                  : item}
              </Typography>
            );
          }}
          sx={{
            width: width ? width : 250,
             ['@media (max-width:550px)']: { 
                width: '100%'
              }
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}

          {items.map((name) => (
            <MenuItem key={name} value={name}>
              {isMultiple && (
                <Checkbox
                  checked={selectedList.indexOf(name) > -1}
                  sx={{
                    color: theme.palette.main.blackShade,
                    "&.Mui-checked": {
                      color: theme.palette.main.blackShade,
                    },
                  }}
                />
              )}

              <ListItemText
                primary={name}
                sx={{ color: theme.palette.main.blackShade }}
              />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </div>
  );
}

FilterMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  selectedList: PropTypes.arrayOf(PropTypes.string),
  setSelectedList: PropTypes.func,
  item: PropTypes.string,
  setItem: PropTypes.func,
  onInputChange: PropTypes.func,
  isMultiple: PropTypes.bool,
  replace: PropTypes.bool,
  defaultReplaceValue: PropTypes.string,
};
