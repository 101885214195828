/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
// import PropTypes from 'prop-types'
import {
  Button,
  Box,
  Divider,
  Typography,
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
  Avatar,
  Skeleton,
} from "@mui/material";
import TextInputLayout from "components/TextInputLayout";
import AuthUser from "api/authUser";
import { getCurrentUser } from "config/auth";
import FilterMenu from "components/FilterMenu";
import { INSTITUTION } from "constants/app";
import { notify } from "util";
import Auth from "api/auth";
import { REACT_APP_CLIENT_AUTH_ENDPOINT } from "config/env";
import LinearLoadingBar from "components/LinearLoadingBar";
import Constants from "api/constants";

const useClasses = (theme) => ({
  button: {
    p: theme.spacing(2),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMarginBtm: {
    mb: theme.spacing(3),
  },
});

// const departments = ["CSE", "EEE", "MPE", "CE", "IPE", "BBA", "ARCH"];
const tShirtSizes = ["S", "M", "L", "XL", "XXL"];
const semesters = ["1", "2"];
const years = ["1", "2", "3", "4", "5"];

const Profile = () => {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [profile, setProfile] = React.useState([]);
  const [isAlumni, setIsAlumni] = React.useState(false);
  const [form, setForm] = React.useState({});
  // const [image, setImage] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [preview, setPreview] = React.useState("");
  const [previewUrl, setPreviewUrl] = React.useState("");
  //  ------ dropdowns. take them to a single hook later
  const [tshirtSize, setTshirtSize] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [departments, setDepartments] = React.useState([]);
  const [year, setYear] = React.useState("");
  const [semester, setSemester] = React.useState("");
  const [isAustStudent, setIsAustStudent] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  useEffect(() => {
    const api = new AuthUser();

    api.getProfileInfo().then((res) => {
      let data = res.message;
      setProfile(data);
      console.log(data);

      setPreview(data.avatar);
      setPreviewUrl(data.avatar);

      data.isAlumni && setIsAlumni(true);
      console.log(getCurrentUser().profile);

      if (getCurrentUser().profile.institution === INSTITUTION) {
        setIsAustStudent(true);
      }

      setLoading(false);
    });

    const consApi = new Constants();
    consApi
      .getConstants("departments")
      .then((res) => {
        setDepartments(res?.message?.departments || []);
      })
      .catch((err) => {
        notify(err, "error");
      });

    // if (image) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setPreview(reader.result);
    //   };
    //   reader.readAsDataURL(image);
    // } else {
    //   setPreview(null);
    // }
  }, []);

  const handleFilterChange = (event, value) => {
    console.log(value);
  };

  const onInput = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSaveChangesClick = () => {
    form.isAlumni = isAlumni;
    department ? (form.department = department) : null;
    year ? (form.year = year) : null;
    semester ? (form.semester = semester) : null;
    tshirtSize ? (form.tShirtSize = tshirtSize) : null;

    setProcessing(true);

    const api = new AuthUser();
    api
      .putProfileInfo(form)
      .then((res) => {
        console.log(res);
        notify(res.message, "success");
        setProcessing(false);

        if (form.handle) {
          const api = new Auth();
          api
            .doSignOut()
            .then((res) => {
              console.log(res);
              notify(
                "You must sign in again to incorporate the changes!",
                "info"
              );
              window.location.href = REACT_APP_CLIENT_AUTH_ENDPOINT;
            })
            .catch((err) => {
              console.log(err);
              notify(err, "error");
            });
        }
      })
      .catch((err) => {
        console.log(err);
        notify(err, "error");
        setProcessing(false);
      });
  };

  const handleCheckAlumni = (event) => {
    setIsAlumni(event.target.checked);
  };

  // const imageHandler = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.substr(0, 5) === "image") {
  //     setImage(file);
  //   } else {
  //     setImage(null);
  //   }
  // };

  if (loading) {
    return (
      <>
        <Skeleton
          variant="rectangular"
          width={150}
          height={130}
          sx={{
            borderRadius: "10%",
          }}
        />
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={340} />
      </>
    );
  }
  return (
    <Box>
      <Box>
        <Box sx={classes.itemMarginBtm}>
          <Box
            component="form"
            sx={{
              display: "inline-grid",
              alignContent: "center",
            }}
          >
            <Avatar
              sx={{
                width: 130,
                height: 130,
                borderRadius: 1,
                objectFit: "contain",
              }}
              variant="square"
              src={previewUrl}
              srcSet={preview}
            />
          </Box>
          {/* <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={imageHandler}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              aria-label="upload picture"
              component="span"
              sx={{
                position: "absolute",
                marginLeft: "-15px",
                marginTop: "90px",
                backgroundColor: theme.palette.main.white,
                boxShadow: theme.customShadows.primary,
              }}
            >
              <EditIcon />
            </IconButton>
          </label> */}
        </Box>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h4" gutterBottom paddingTop={1}>
              Username
            </Typography>
            <TextInputLayout
              // icon="mail"
              placeholder="Enter name"
              value={profile.handle && profile.handle}
              type="text"
              name="handle"
              onInputChange={onInput}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h4" gutterBottom paddingTop={1}>
              Full Name
            </Typography>
            <TextInputLayout
              // icon="mail"
              placeholder="Enter your full name"
              value={profile.fullName && profile.fullName}
              type="name"
              name="fullName"
              onInputChange={onInput}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h4" gutterBottom paddingTop={1}>
              Email
            </Typography>
            <TextInputLayout
              // icon="mail"
              placeholder="Enter your email"
              value={profile.email && profile.email}
              type="email"
              name="email"
              onInputChange={onInput}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h4" gutterBottom paddingTop={1}>
              T-shirt size
            </Typography>
            <FilterMenu
              name={
                profile && profile.tShirtSize ? profile.tShirtSize + "" : "Size"
              }
              item={tshirtSize}
              items={tShirtSizes}
              setItem={setTshirtSize}
              onInputChange={handleFilterChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box>
            <Typography variant="h4" gutterBottom paddingTop={1}>
              Institution
            </Typography>
            <TextInputLayout
              // icon="mail"
              placeholder="Enter institution name"
              value={profile.institution && profile.institution}
              type="text"
              name="institution"
              disabled={true}
              onInputChange={onInput}
            />
          </Box>
        </Grid>
      </Grid>
      {isAustStudent && (
        <>
          {
            <Box sx={classes.itemSpacingSmall}>
              <Typography variant="h4" gutterBottom paddingTop={1}>
                Department
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={10} lg={5}>
                  <FilterMenu
                    name={
                      profile && profile.department
                        ? profile.department + ""
                        : "Department"
                    }
                    item={department}
                    items={departments}
                    setItem={setDepartment}
                    onInputChange={handleFilterChange}
                  />
                </Grid>
              </Grid>
            </Box>
          }

          <Box sx={classes.itemSpacingMedium}>
            <Divider />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                size="medium"
                checked={isAlumni}
                onChange={handleCheckAlumni}
              />
            }
            label={<Typography variant="h4">Are you an alumni?</Typography>}
          />
          {!isAlumni && (
            <>
              <Box sx={classes.itemSpacingMedium}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="h4" gutterBottom paddingTop={1}>
                      Select Year
                    </Typography>
                    <FilterMenu
                      name={
                        profile && profile.year ? profile.year + "" : "Year"
                      }
                      item={year}
                      items={years}
                      setItem={setYear}
                      onInputChange={handleFilterChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="h4" gutterBottom paddingTop={1}>
                      Select Semester
                    </Typography>
                    <FilterMenu
                      name={
                        profile && profile.semester
                          ? profile.semester + ""
                          : "Semester"
                      }
                      item={semester}
                      items={semesters}
                      setItem={setSemester}
                      onInputChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          {isAlumni && (
            <>
              <Typography variant="h4" gutterBottom paddingTop={3}>
                Select Batch
              </Typography>
              <TextInputLayout
                // icon="mail"
                placeholder="Enter your batch number"
                value={profile.batch && profile.batch}
                type="text"
                name="batch"
                onInputChange={onInput}
              />
            </>
          )}
        </>
      )}
      <Box sx={classes.itemSpacingLarge}>
        <Button
          sx={classes.button}
          variant="contained"
          color="secondary"
          onClick={onSaveChangesClick}
          disabled={processing}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

Profile.propTypes = {};

export default Profile;
