import React from "react";

// mui
import { Box, Paper, Typography, useTheme } from "@mui/material";

const useClasses = (theme) => ({
  paper: {
    maxWidth: 600,
    px: theme.spacing(8),
    pb: theme.spacing(2),
    mx: "auto",
    mt: theme.spacing(6),
    boxShadow: theme.customShadows.primary,
  },
  button: {
    p: theme.spacing(2),
  },
  itemSpacing: {
    py: theme.spacing(1),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
});

const Fail = () => {
  const theme = useTheme();
  const classes = useClasses(theme);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Paper sx={classes.paper}>
        <Box sx={classes.itemSpacingLarge}>
          <Typography sx={{ textAlign: "center" }} variant="h3">
            Oops
          </Typography>
        </Box>
        <Box sx={classes.itemSpacing}>
          <Typography sx={{ textAlign: "center" }} variant="body1">
            The link may have expired or maybe you are trying to tweak it? Try
            requesting for a new verification link
          </Typography>
        </Box>
        <Box sx={{ padding: theme.spacing(2) }} />
      </Paper>

      <Box sx={{ padding: theme.spacing(5) }} />
    </Box>
  );
};
Fail.propTypes = {
  //   onInput: PropTypes.func.isRequired,
  //   onSubmitClick: PropTypes.func.isRequired,
};

export default Fail;
