import { parseError, parseResponse } from "util/payloadHandler";
import Api from "./api";
import Cookies from "universal-cookie";
import { ACCESS_TOKEN } from "config/env";

export default class Auth extends Api {
  constructor() {
    super();
    this.init("/api/auth/v1");
  }

  /**
   * Sign out the user
   */
  async doSignOut() {
    try {
      console.log("sign out");
      const res = await this.client.post(`/sign-out`);
      const { config } = res.data;

      console.log("config", config);

      // clear a cookie
      const cookies = new Cookies();
      cookies.remove(ACCESS_TOKEN, {
        domain: config.domain,
        path: config.path,
      });

      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }
}
