import { useTheme } from "@emotion/react";
import { Box, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell } from "../Utils";
import General from "api/general";
import { verdictColor } from "util";
import SubmissionStatusDialog from "components/SubmissionStatusDialog";

const useClasses = (theme) => ({
  titleSpacing: {
    mt: theme.spacing(2),
  },
  tableMargin: {
    pt: theme.spacing(3),
  },
  itemSpacingMedium: {
    py: theme.spacing(2),
  },
  information: {
    p: "0px 15px 0 10px",
  },
  informationContainer: {
    display: "flex",
  },
  itemSpacingLarge: {
    py: theme.spacing(3),
  },
  itemSpacingSmall: {
    py: theme.spacing(1),
  },
  itemMargin: {
    pb: theme.spacing(2),
  },
  button: {
    px: theme.spacing(5),
  },
});

const headCells = [
  "Submitted at",
  "Verdict",
  "Language",
  "Memory",
  "Runtime",
  "Passed",
];

export default function SubmissionsPage() {
  const theme = useTheme();
  const classes = useClasses(theme);
  const [openSubmission, setOpenSubmission] = useState({
    open: false,
    id: null,
  });
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    try {
      const urlParts = window.location.href.split("/");
      const name = urlParts[urlParts.length - 1];

      const api = new General();
      api
        .getProblemSubmissions(name)
        .then((res) => {
          console.log("res", res);
          setSubmissions(res.message);
        })
        .catch((err) => {
          console.trace(err);
          setSubmissions([]);
        });
    } catch (e) {
      console.trace(e);
    }
  }, []);

  const onSubmissionViewClick = (id) => {
    console.log(id);
    setOpenSubmission({
      open: true,
      id: id,
    });
  };

  return (
    <Box
      sx={
        ((classes.itemSpacingLarge, classes.tableMargin),
        {
          minHeight: "75vh",
        })
      }
    >
      {submissions.length > 0 && (
        <TableContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <StyledTableCell key={index} align="left">
                  {headCell}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => onSubmissionViewClick(submission._id)}
                >
                  <Typography variant="body2" color="secondary">
                    {new Date(submission.status.submittedAt).toLocaleString()}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    variant="body2"
                    color={verdictColor(submission.status.verdict, theme)}
                  >
                    {submission.status.verdict}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="body2" color="secondary">
                    {submission.language}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="body2" color="secondary">
                    {parseInt(submission.status.memory)}
                    {" kb"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="body2" color="secondary">
                    {submission.status.time}
                    {" ms"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="body2" color="secondary">
                    {submission.status.totalTestcasesPassed}
                    {submission.status.totalTestcases && "/"}
                    {submission.status.totalTestcases || ""}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      )}
      {submissions.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
          }}
        >
          <Typography variant="h5" color="secondary">
            No submissions yet
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            sx={{
              alignSelf: "center",
              textAlign: "center",
              mt: 1,
            }}
          >
            Hurry up and submit your first submission!
          </Typography>
        </Box>
      )}
      {openSubmission.open && (
        <SubmissionStatusDialog
          open={openSubmission.open}
          handleClose={() =>
            setOpenSubmission({
              open: false,
              id: null,
            })
          }
          type="view"
          id={openSubmission.id}
        />
      )}
    </Box>
  );
}
