import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import IconMapper from "components/IconMapper";
import { useTheme } from "@emotion/react";

const Adornment = (props) => {
  const theme = useTheme();
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        edge="end"
      >
        {props.visible ? (
          <IconMapper name="visibilityOn" color={theme.palette.main.darkAsh} />
        ) : (
          <IconMapper name="visibilityOff" color={theme.palette.main.darkAsh} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

Adornment.propTypes = {
  onClick: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Adornment;
