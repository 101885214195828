import Router from "routes/routes";
import GlobalStyles from "theme/globalStyles";
import ScrollToTop from "components/ScrollToTop";
import ThemeConfig from "theme";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 3000,
  draggable: false,
  closeButton: true,
  closeOnClick: true,
});

function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Router />
    </ThemeConfig>
  );
}

export default App;
