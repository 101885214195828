const NODE_ENV = process.env.NODE_ENV;
const SERVER_ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT;

const REACT_APP_CLIENT_ENDPOINT = process.env.REACT_APP_CLIENT_ENDPOINT;
const REACT_APP_CLIENT_ADMIN_ENDPOINT =
  process.env.REACT_APP_CLIENT_ADMIN_ENDPOINT;
const REACT_APP_CLIENT_AUTH_ENDPOINT =
  process.env.REACT_APP_CLIENT_AUTH_ENDPOINT;
const REACT_APP_CLIENT_MANAGER_ENDPOINT =
  process.env.REACT_APP_CLIENT_MANAGER_ENDPOINT;

const ACCESS_TOKEN = "ACCESS_TOKEN";

export {
  NODE_ENV,
  SERVER_ENDPOINT,
  REACT_APP_CLIENT_ENDPOINT,
  REACT_APP_CLIENT_ADMIN_ENDPOINT,
  REACT_APP_CLIENT_AUTH_ENDPOINT,
  REACT_APP_CLIENT_MANAGER_ENDPOINT,
  ACCESS_TOKEN,
};
