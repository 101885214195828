const { default: styled } = require("@emotion/styled");
const { Chip } = require("@mui/material");
import PropTypes from "prop-types";

const StyledChip = styled(Chip)(({theme,backgroundColor }) => ({
  backgroundColor: backgroundColor,
  padding: theme.spacing(1),
  color: theme.palette.main.black,
  // add marginRight to all childs except last
  marginBottom: theme.spacing(1),
  "&:not(:last-child)": {
    marginRight: theme.spacing(1),
  },
}));

StyledChip.propTypes = {
  backgroundColor:PropTypes.string,
};


export default StyledChip;
