import { Typography, Card, Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const Notice = ({ notice }) => {
  const { title, content, createdAt } = notice;
  const date = new Date(createdAt);
  const year = date.getFullYear().toString().substr(2);
  const month = date.getMonth() + 1;
  const monthAbbr = new Date(date).toLocaleString("default", {
    month: "short",
  });

  const day = date.getDate();

  console.log(year, month, day);
  return (
    <Card
      sx={{
        display: "flex",
        height: "100%",
        boxShadow: "none",
        backgroundColor: (theme) => theme.palette.main.lightAsh2,
      }}
    >
      <Box
        sx={{
          px: 5,
          py: 2,
          alignItems: "center",
          // align vertically
          display: "flex",
          // align horizontally
          color: (theme) => theme.palette.main.white,
          backgroundColor: "#855CF8",
          height: "100%",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h1">{day}</Typography>
          <Typography variant="h4">
            {monthAbbr}
            {year}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          py: 2,
          px: 2,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Typography
          variant="body2"
          sx={{
            mt: 1,
          }}
        >
          {content.substring(0, 60) + "..."}
        </Typography>
      </Box>
    </Card>
  );
};

// docs: https://reactjs.org/docs/typechecking-with-proptypes.html
Notice.propTypes = {
  notice: PropTypes.object.isRequired,
};

export default Notice;
