import { Avatar, Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./JudgeTags.module.css";
import randomColor from "randomcolor";
import PropTypes from "prop-types";
// icons
import CodeForcesIcon from "assets/judge-icons/codeforces_logo.png";
import AtCoderIcon from "assets/judge-icons/atcoder_logo.png";
import CodeChefIcon from "assets/judge-icons/codechef_logo.png";
import HackerRankIcon from "assets/judge-icons/hackerrank_logo.png";
import SpojIcon from "assets/judge-icons/spoj_logo.png";
import UvaIcon from "assets/judge-icons/uva_logo.png";
import TimusIcon from "assets/judge-icons/timus_logo.png";
import TophIcon from "assets/judge-icons/toph_logo.png";
import { ONLINE_JUDGES_LINK } from "constants/app";

const getJudgeIcon = (name) => {
  switch (name.toLowerCase()) {
    // judge tags
    case "codechef":
      return CodeChefIcon;
    case "codeforces":
      return CodeForcesIcon;
    case "hackerrank":
      return HackerRankIcon;
    case "spoj":
      return SpojIcon;
    case "uva":
      return UvaIcon;
    case "atcoder":
      return AtCoderIcon;
    case "timus":
      return TimusIcon;
    case "toph":
      return TophIcon;
    default:
      return CodeChefIcon;
  }
};

const JudgeTags = ({ onlineJudges }) => {
  console.log("onlineJudges", onlineJudges);
  return (
    <Box
      className={styles.judgeTagsContainer}
      sx={{
        px: 4,
        py: 3,
      }}
    >
      <Typography variant="h3" component="div">
        Judge Tags
      </Typography>
      <Box
        direction="row"
        spacing={1}
        sx={{
          mt: 1,
        }}
      >
        {ONLINE_JUDGES_LINK.map((onlineJudge, index) => (
          <Chip
            label={
              onlineJudges && onlineJudges[onlineJudge.name]
                ? onlineJudges[onlineJudge.name]
                : "-"
            }
            size="medium"
            avatar={<Avatar src={getJudgeIcon(onlineJudge.name)} />}
            sx={{
              borderRadius: "4px",
              mr: 1,
              mt: 1,
              backgroundColor: randomColor({
                luminosity: "light",
                format: "hsla",
                seed: onlineJudge.name,
              }),
              minWidth: 80,
            }}
            onClick={() => {
              if (onlineJudges && onlineJudges[onlineJudge.name]) {
                window.open(
                  `${onlineJudge.link}${
                    onlineJudges[onlineJudge.name]
                  }`,
                  "_blank"
                );
              }
            }}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};

JudgeTags.propTypes = {
  onlineJudges: PropTypes.object,
};

export default JudgeTags;
