import { parseError, parseResponse } from "util/payloadHandler";
import Api from "./api";

export default class General extends Api {
  constructor(timeout = 5000) {
    super();
    this.init("/api/v1/general", timeout);
  }

  async listBlogs() {
    try {
      const res = await this.client.get(`/blogs`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listBlogType() {
    try {
      const res = await this.client.get(`/constants/blogTypes`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listAnouncements() {
    try {
      const res = await this.client.get(`/announcements`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listPinnedAnouncement() {
    try {
      const res = await this.client.get(`/announcements/pinned`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listLeaderboard(type) {
    try {
      const res = await this.client.get(`/leaderboard/${type.toLowerCase()}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listActiveSolvers() {
    try {
      const res = await this.client.get(`/leaderboard/active-solvers`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listProblems() {
    try {
      const res = await this.client.get(`/problems`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getProblem(name) {
    try {
      const res = await this.client.get(`/problem/${name}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getProblemSubmissions(name) {
    try {
      const res = await this.client.get(`/problem/${name}/submissions`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getEditorial(name) {
    try {
      const res = await this.client.get(`/problem/${name}/editorial`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async submitProblem(name, language, source) {
    try {
      const res = await this.client.post(`/problem/submitProblem`, {
        name,
        source,
        language,
      });
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async submitProblemTest(name, language, source) {
    try {
      const res = await this.client.post(`/problem/submitProblemTest`, {
        name,
        source,
        language,
      });
      console.log(res);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async listSubmissions() {
    try {
      const res = await this.client.get(`/submissions`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getSubmission(id) {
    try {
      const res = await this.client.get(`/submission/${id}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }

  async getProfile(handle) {
    try {
      const res = await this.client.get(`/profile/${handle}`);
      return parseResponse(res);
    } catch (error) {
      return parseError(error);
    }
  }
}
