import { Box, Paper, Stack, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import SubmissionsTable from "./SubmissionsTable";
import { useTheme } from "@emotion/react";
import TextInputLayout from "components/TextInputLayout";
import StyledChip from "components/StyledChip";
import FilterMenu from "components/FilterMenu";
import { upperCaseFirst } from "upper-case-first";
import { getCurrentUser } from "config/auth";
import General from "api/general";
import Constants from "api/constants";
import { extensionlanguages } from "constants/app";
import { Helmet } from "react-helmet-async";

const HeaderBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  textAlign: "left",
  boxShadow: theme.customShadows.primary,
}));

const getUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const handle = urlParams.get("handle");
  const problem = urlParams.get("problem");

  return {
    handle,
    problem,
  };
};

export default function SubmissionsPage() {
  const theme = useTheme();
  const [chipData, setChipData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [form, setForm] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [filterItems, setFilterItems] = React.useState([]);

  useEffect(() => {
    setUser(getCurrentUser());

    const apiConstants = new Constants();
    apiConstants.getConstants("languages").then((res) => {
      console.log(res);
      setFilterItems(res.message.language);
    });

    const api = new General();

    api
      .listSubmissions()
      .then((res) => {
        console.log("res", res);
        let data = res.message;
        setRows(data);

        const { handle } = getUrlParams();
        // this is for the urls that contains a user's handle in the url
        if (handle) {
          setForm((prevState) => ({
            ...prevState,
            ["handleSearch"]: handle,
          }));

          data = data.filter((row) => {
            return row.author.toLowerCase().includes(handle.toLowerCase());
          });
        }
        // if (problem) {
        //   setForm({
        //     ...form,
        //     problemSearch: problem,
        //   });
        //   data = rows.filter((row) => {
        //     return row.problemName
        //       .toLowerCase()
        //       .includes(problem.toLowerCase());
        //   });
        // }
        setFilterData(data);
      })
      .catch((err) => {
        console.log("res", err);
      });
  }, []);

  const onInputChange = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearchClick = () => {
    const filteredData = rows.filter((row) => {
      return row.problemName
        .toLowerCase()
        .includes(form["problemSearch"].toLowerCase());
    });

    setFilterData(filteredData);
  };

  const handleAuthorClick = () => {
    const filteredData = rows.filter((row) => {
      console.log(row);
      return row.author
        .toLowerCase()
        .includes(form["handleSearch"].toLowerCase());
    });

    setFilterData(filteredData);
  };

  const handleChipChange = (e, value) => {
    if (value.length) {
      const temp = rows.filter((row) => {
        return value.includes(extensionlanguages[row.language]);
      });
      setFilterData(temp);
    } else {
      setFilterData(rows);
    }
  };

  const handleChipDelete = (chipToDeleteIndex) => () => {
    // filter out the chip that was clicked
    const chipToDelete = chipData[chipToDeleteIndex];
    const updatedChips = chipData.filter((chip) => chip !== chipToDelete);
    setChipData(updatedChips);

    // update the filtered data
    if (updatedChips.length) {
      const temp = rows.filter((row) =>
        updatedChips.includes(upperCaseFirst(row.language))
      );
      setFilterData(temp);
    } else {
      setFilterData(rows);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Submissions</title>
      </Helmet>
      {/* header box */}
      <HeaderBox sx={{ pl: 3 }}>
        <Stack direction="row" spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextInputLayout
                placeholder="Problem"
                type="search"
                name="problemSearch"
                onInputChange={onInputChange}
                // width={40}
                height={1}
                padding={1}
                onActionClick={handleSearchClick}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputLayout
                placeholder="Handle"
                type="search"
                value={getUrlParams().handle || ""}
                name="handleSearch"
                height={1}
                onInputChange={onInputChange}
                padding={1}
                onActionClick={handleAuthorClick}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FilterMenu
                items={filterItems || []}
                placeholder="Select Language"
                name="Language"
                width={180}
                selectedList={chipData}
                setSelectedList={setChipData}
                onInputChange={handleChipChange}
                isMultiple
              />
            </Grid>
          </Grid>
          {/* <TextInputLayout
            placeholder="Problem"
            type="search"
            name="problemSearch"
            onInputChange={onInputChange}
            width={25}
            padding={1}
            onActionClick={handleSearchClick}
          />

          <TextInputLayout
            placeholder="Handle"
            type="search"
            name="handleSearch"
            width={25}
            onInputChange={onInputChange}
            padding={1}
            onActionClick={handleAuthorClick}
          />
          <FilterMenu
            items={filterItems.language}
            placeholder="Select Language"
            name="Language"
            width={180}
            selectedList={chipData}
            setSelectedList={setChipData}
            onInputChange={handleChipChange}
            isMultiple
          /> */}
          {/* <FilterMany
            items={filterItems.verdict}
            placeholder="Select Verdict"
            name="Verdict"
            width={180}
            selectedList={chipData}
            setSelectedList={setChipData}
            onInputChange={handleChipChange}
          /> */}
        </Stack>
      </HeaderBox>
      <Box sx={{ mt: 2 }} />
      <Box
        sx={{
          borderRadius: 1,
          boxShadow: theme.customShadows.primary,
          padding: "15px 30px 25px 30px",
          backgroundColor: theme.palette.main.white,
        }}
      >
        <Typography variant="h3" gutterBottom paddingTop={1}>
          Submissions
        </Typography>

        <Box sx={{ mt: 2 }}>
          {chipData.map((data, index) => {
            return (
              <StyledChip
                key={index}
                label={data}
                onDelete={handleChipDelete(index)}
                size="small"
              />
            );
          })}
        </Box>

        <SubmissionsTable filter={chipData} data={filterData} user={user} />
      </Box>
    </React.Fragment>
  );
}

SubmissionsPage.propTypes = {};
