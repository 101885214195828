import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import {
  WINDOW_MARGIN,
  WINDOW_MARGIN_MD,
  WINDOW_MARGIN_SM,
} from "constants/dimens";
import { useState } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [open, setOpen] = useState(false);
  console.log(open);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: theme.palette.main.background,
      }}
    >
      <Navbar onOpenSidebar={() => setOpen(true)} />
      <Box
        sx={{
          mt: theme.spacing(2),
          padding: WINDOW_MARGIN,

          "@media (max-width: 1500px)": {
            padding: WINDOW_MARGIN_MD,
          },

          [theme.breakpoints.down("sm")]: {
            padding: WINDOW_MARGIN_SM,
          },
        }}
      >
        <Outlet />
      </Box>

      <Box sx={{ mt: 5 }} />
      <Footer />
    </Box>
  );
};
export default Layout;
