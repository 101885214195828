import React from "react";
import "./AccordionUserDropDown.css";
// import PropTypes from 'prop-types'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import {
  // PROBLEMS,
  // LEADERBOARD,
  PROFILE,
  SETTINGS,
  SUBMISSIONS,
} from "constants/route";
import httpStatus from "http-status";
import Auth from "api/auth";
import { getCurrentUser } from "config/auth";
import { useNavigate } from "react-router-dom";
import { notify } from "util";

const USER_DROPDOWN = {
  link: [
    {
      text: "Profile",
      to: PROFILE,
    },
    {
      text: "My Submissions",
      to: SUBMISSIONS,
    },
    // {
    //   text: "My Contests",
    //   to: PROBLEMS,
    // },
    {
      text: "Settings",
      to: SETTINGS,
    },
    // {
    //   text: "Help & Support",
    //   to: LEADERBOARD,
    // },
  ],
  authlink: {
    logout: {
      text: "Logout",
      to: "/",
    },
  },
};

const AccordionUserDropDown = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onSignOutClick = () => {
    console.log("sign out click!!");

    const auth = new Auth();
    auth
      .doSignOut()
      .then((res) => {
        console.log(res);
        if (res.status == httpStatus.OK) {
          window.location.href = USER_DROPDOWN.authlink.logout.to;
        }
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
      });
  };

  return (
    <>
      <Accordion
        sx={{
          maxWidth: "200px",
          mb: 1,
          minWidth: "200px",
          mt: 1,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            justifyContent: "center",
            bgcolor: theme.palette.main.lightAsh,
            mx: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="h4" color={theme.palette.main.purple}>
            {/* TODO: string split to avoid overflow */}
            {getCurrentUser()?.profile?.handle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "grid",
            p: 0,
            mx: 2,
            mt: 0.5,
            mb: 1,
            bgcolor: theme.palette.main.lightAsh,
            borderRadius: 1,
          }}
        >
          {USER_DROPDOWN.link.map((item, index) => {
            let to = item.to;
            const handle = getCurrentUser()?.profile?.handle;
            if (item.text === "My Submissions") {
              to = `${to}?handle=${handle}`;
            } else if (item.text === "Profile") {
              to = `${to}?handle=${handle}`;
            }
            return (
              <Button
                variant="text"
                fullWidth={true}
                key={index}
                onClick={() => navigate(to)}
              >
                <Typography variant="h4">{item.text}</Typography>
              </Button>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Button
        fullWidth
        size="medium"
        variant="secondary"
        color="dark"
        onClick={onSignOutClick}
        sx={{
          color: theme.palette.main.white,
          bgcolor: theme.palette.main.red,
          px: theme.spacing(6),
          py: 2,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          // remove hover effect
          "&:hover": {
            backgroundColor: "#FA4F4F",
          },
        }}
      >
        {USER_DROPDOWN.authlink.logout.text}
      </Button>
    </>
  );
};

AccordionUserDropDown.propTypes = {};

export default AccordionUserDropDown;
