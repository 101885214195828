import React, { useEffect } from "react";
import { Card, Typography, Avatar, Divider, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { LEADERBOARD } from "constants/route";
import { useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import General from "api/general";
import { REACT_APP_CLIENT_ENDPOINT } from "config/env";

const ActiveSolver = () => {
  const navigate = useNavigate();
  const [solvers, setSolvers] = React.useState([]);

  useEffect(() => {
    const api = new General();
    api
      .listActiveSolvers()
      .then((res) => {
        console.log(res);
        setSolvers(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Card
      sx={{ py: 2, px: 3, boxShadow: (theme) => theme.customShadows.primary }}
    >
      <Typography variant="h3" sx={{ mb: 2 }}>
        Most Active Solvers
      </Typography>
      {solvers.map((user, index) => (
        <Box
          key={index}
          sx={{
            pt: index == 0 ? 1 : 0,
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={8}
              lg={8}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  `${REACT_APP_CLIENT_ENDPOINT}/profile?handle=${user.handle}`
                )
              }
            >
              <Avatar
                alt="Travis Howard"
                src={
                  user.avatar ||
                  `https://avatars.dicebear.com/api/initials/${user.handle}.svg`
                }
              />
              <Typography
                variant="h4"
                sx={{
                  pl: 2,
                }}
              >
                {user.handle}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              lg={4}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  ["@media (max-width:900px)"]: {
                    pl: 20,
                  },
                  ["@media (max-width:872px)"]: {
                    pl: 18,
                  },
                  ["@media (max-width:820px)"]: {
                    pl: 16,
                  },
                  ["@media (max-width:762px)"]: {
                    pl: 14,
                  },
                  ["@media (max-width:708px)"]: {
                    pl: 11,
                  },
                  ["@media (max-width:630px)"]: {
                    pl: 9,
                  },

                  ["@media (max-width:512px)"]: {
                    pl: 6.5,
                  },
                  ["@media (max-width:448px)"]: {
                    pl: 4,
                  },
                  ["@media (max-width:384px)"]: {
                    pl: 2,
                  },
                }}
              >
                Solved: <b>{user.submissions.length}</b>
              </Typography>
            </Grid>
          </Grid>
          {index !== solvers.length - 1 && <Divider sx={{ my: 1 }} />}
        </Box>
      ))}

      {solvers.length === 0 && (
        <Typography variant="body1">
          No active solvers yet. Be the first!
        </Typography>
      )}
      <Typography sx={{ textAlign: "center", mt: 2 }}>
        <Button
          sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
          onClick={() => navigate(LEADERBOARD)}
        >
          View All
        </Button>
      </Typography>
    </Card>
  );
};

ActiveSolver.propTypes = {
  solvers: PropTypes.array,
};

export default ActiveSolver;
