import React from "react";
import styles from "./Blog.module.css";
import { Box, Chip, Divider, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PropTypes from "prop-types";
import { getRelativeTime } from "util";
import randomColor from "randomcolor";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

const BlogPost = ({ post }) => {
  const { title, tags, author, createdAt, content } = post;
  const navigate = useNavigate();
  return (
    <Box className={styles.blogContainer}>
      <Typography className={styles.blogTitle} variant="h3" color="primary">
        {title}
      </Typography>
      <Box className={styles.blogCatagoryContainer} direction="row">
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            sx={{
              borderRadius: "4px",
              mr: 1,
              mt: 1,
              backgroundColor: randomColor({
                luminosity: "light",
                format: "hsla",
                seed: tag,
              }),
            }}
            size="small"
          />
        ))}
      </Box>
      <Box className={styles.infoContainer}>
        <AccountCircleIcon />
        <Typography
          variant="subtitle1"
          className={styles.information}
          onClick={() => {
            navigate(`/profile?handle=${author}`);
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          {author}
        </Typography>
        <DateRangeIcon />
        <Typography variant="subtitle1" className={styles.information}>
          {getRelativeTime(createdAt)}
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <ReactMarkdown>{content || "N/A"}</ReactMarkdown>

      {/* <Typography
        variant="body1"
        sx={{
          mt: 1,
          color: (theme) => theme.palette.main.black,
        }}
      >
        {content}
      </Typography> */}
    </Box>
  );
};

BlogPost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default BlogPost;
