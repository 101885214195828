import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Chip, Divider, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import SubmissionStatusDialog from "components/SubmissionStatusDialog";
import { getSubmissionStatusFullName, getAcronym } from "util/index";
import { extensionlanguages } from "constants/app";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "# Id",
  },
  {
    id: "problemName",
    numeric: false,
    disablePadding: false,
    label: "Problem",
  },
  {
    id: "authorName",
    numeric: false,
    disablePadding: false,
    label: "Handle",
  },
  {
    id: "submittedAt",
    numeric: false,
    disablePadding: false,
    label: "Submitted At",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: false,
    label: "Language",
  },

  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time(ms)",
  },
  {
    id: "memory",
    numeric: false,
    disablePadding: false,
    label: "Memory(KB)",
  },
  {
    id: "verdict",
    numeric: false,
    disablePadding: false,
    label: "Verdict",
  },
];

const rowRadius = 10;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // add border to only first and last child
  backgroundColor: theme.palette.main.lightAsh,

  "&:first-child": {
    borderTopLeftRadius: rowRadius,
    borderBottomLeftRadius: rowRadius,
  },
  "&:last-child": {
    borderTopRightRadius: rowRadius,
    borderBottomRightRadius: rowRadius,
  },

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.body1.fontSize,
    color: "secondary",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  backgroundColor: "transparent",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, user } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (!user.isAuthenticated && headCell.id === "status") {
            return <React.Fragment key={headCell.id} />;
          }
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "title" ||
                headCell.id == "number" ||
                headCell.id == "id"
                  ? "left"
                  : "center"
              }
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h6" color="secondary">
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/**
 *
 * @param {String} verdict any of
 * ["Accepted", "Wrong Answer", "Time Limit Exceeded", "Compilation Error", "Runtime Error", "Memory Limit Exceeded"]
 * @returns {Node}
 */
const GetChipColor = (verdict) => {
  verdict = verdict.toLowerCase();
  switch (verdict) {
    case "ac":
      return (
        <Chip
          label={getAcronym(verdict)}
          color="success"
          sx={{
            color: "white",
            px: 2,
          }}
        />
      );
    case "wa":
      return (
        <Chip
          label={getAcronym(verdict)}
          color="error"
          sx={{
            color: "secondary",
            px: 2,
          }}
        />
      );
    case "tle":
      return (
        <Chip
          label={getAcronym(verdict)}
          color="warning"
          sx={{
            px: 2,
          }}
        />
      );

    case "ce":
      return (
        <Chip
          label={getAcronym(verdict)}
          color="info"
          sx={{
            px: 2,
          }}
        />
      );

    case "re":
      return (
        <Chip
          label={getAcronym(verdict)}
          color="secondary"
          sx={{
            px: 2,
          }}
        />
      );

    case "mle":
      return (
        <Chip
          label={getAcronym(verdict)}
          sx={{
            px: 2,
            backgroundColor: (theme) => theme.palette.main.softGreen,
          }}
        />
      );

    default:
      return (
        <Chip
          label="Unknown"
          color="secondary"
          sx={{
            px: 2,
          }}
        />
      );
  }
};

export default function SubmissionsTable({ data, user }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("submittedAt");
  const [page, setPage] = React.useState(0);
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openSubmission, setOpenSubmission] = React.useState({
    open: false,
    id: null,
  });
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    console.log(property);

    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmissionViewClick = (id) => {
    setOpenSubmission({
      id: id,
      open: true,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, backgroundColor: "transparent" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              user={user}
            />

            <TableBody
              sx={{
                backgroundColor: "transparent",
              }}
            >
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    px: 0,
                    pt: 1,
                    pb: 2,
                  }}
                >
                  <Divider />
                </TableCell>
              </TableRow>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      tabIndex={-1}
                      key={row._id || index}
                      sx={{
                        // !todo: @matcovic fix the gap problem
                        borderBottom: 5,
                        borderColor: theme.palette.main.white,
                      }}
                    >
                      <StyledTableCell
                        component="th"
                        id={index}
                        scope="row"
                        align="left"
                        sx={{
                          cursor: "pointer",
                          // add hover effect
                        }}
                        onClick={() => {
                          onSubmissionViewClick(row._id);
                        }}
                      >
                        <u>
                          {
                            // select only first 5 characters
                            row._id.length > 5
                              ? row._id.substring(0, 5)
                              : row._id
                          }
                        </u>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/problem/${row.uniqueTitle}`);
                        }}
                      >
                        {row.problemName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/profile?handle=${row.author}`);
                        }}
                      >
                        {row.author}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {new Date(
                          (row.submittedAt = row.status.submittedAt)
                        ).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {extensionlanguages[row.language]}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {parseFloat(row.status.time).toFixed(1)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {parseInt(row.status.memory)}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Tooltip
                          title={getSubmissionStatusFullName(
                            row.status.verdict
                          )}
                          placement="top"
                          arrow
                        >
                          {GetChipColor(row.status.verdict)}
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {openSubmission.open && (
        <SubmissionStatusDialog
          type="view"
          id={openSubmission.id}
          open={openSubmission.open}
          handleClose={() =>
            setOpenSubmission({
              open: false,
              id: null,
            })
          }
        />
      )}
    </Box>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  user: PropTypes.object,
};

SubmissionsTable.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object,
};
