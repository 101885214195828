import { createAvatar } from "@dicebear/avatars";
import { toast } from "react-toastify";

/**
 *
 * @param {*} sentence the string to parse
 * @param {*} capitalize [optional] parameter to capitalize the first letter of the sentence
 * @returns the acronym of the sentence
 */
const getAcronym = (sentence, capitalize) => {
  if (!capitalize) {
    sentence = sentence.toUpperCase();
  }
  var matches = sentence.match(/\w/g);
  var acronym = matches.join("");
  return acronym;
};

const createRandomAvatar = () => {
  return createAvatar();
};

const getSubmissionStatusFullName = (status) => {
  switch (status.toUpperCase()) {
    case "AC":
      return "Accepted";
    case "WA":
      return "Wrong Answer";
    case "TLE":
      return "Time Limit Exceeded";
    case "RE":
      return "Runtime Error";
    case "CE":
      return "Compilation Error";
    default:
      return "Unknown";
  }
};

const verdictColor = (verdict, theme) => {
  if (!verdict) return theme.palette.main.black;
  switch (verdict.toUpperCase()) {
    case "AC":
      return theme.palette.main.green;
    case "WA":
      return theme.palette.main.red;
    case "TLE":
      return theme.palette.main.yellow;
    case "RE":
      return theme.palette.main.orange;
    case "CE":
      return theme.palette.main.blue;
    default:
      return theme.palette.main.black;
  }
};

const getRelativeTime = (timeStamp) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  const current = new Date();
  timeStamp = new Date(timeStamp);
  var elapsed = current - timeStamp;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + "s ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + "m ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + "hrs ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + "d ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
};

/**
 *
 * @param {*} text
 * @param {*} type [dark, success, warning, error, info]
 * @returns
 */
const notify = (text, type) =>
  toast[type](text, {
    position: "top-center",
    autoClose: 3000,
    pauseOnHover: true,
  });

export {
  getAcronym,
  createRandomAvatar,
  getRelativeTime,
  verdictColor,
  getSubmissionStatusFullName,
  notify,
};
