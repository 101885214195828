import { Navigate, useRoutes } from "react-router-dom";
import NotFound from "components/404";
import {
  HOME,
  NOT_FOUND,
  UNAUTHORIZED,
  PROBLEMS,
  SUBMISSIONS,
  LEADERBOARD,
  SETTINGS,
  VERIFICATION,
  PROBLEM,
  PROFILE,
  ANNOUNCEMENTS,
} from "constants/route";
import Unauthorized from "components/401";
import LandingPage from "pages/LandingPage";
import StoryBook from "pages/StoryBook";
import ProblemPage from "pages/ProblemPage";
import Layout from "pages/Layout";
import ProblemsPage from "pages/ProblemsPage";
import SubmissionsPage from "pages/SubmissionsPage";
import LeaderboardPage from "pages/LeaderboardPage";
import VerificationPage from "pages/VerificationPage";
import SettingsPage from "pages/SettingsPage";
import ProfilePage from "pages/ProfilePage";
import Announcements from "pages/Announcements";

/**
 *
 * Pages that require a navbar and a footer are wrapped in the Layout component.
 */
function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: HOME, element: <LandingPage /> },
        { path: UNAUTHORIZED, element: <Unauthorized /> },
        {
          path: LEADERBOARD,
          element: <LeaderboardPage />,
        },
        { path: SUBMISSIONS, element: <SubmissionsPage /> },
        { path: PROBLEMS, element: <ProblemsPage /> },
        { path: "/storybook", element: <StoryBook /> },
        { path: NOT_FOUND, element: <NotFound /> },
        { path: VERIFICATION, element: <VerificationPage /> },
        { path: SETTINGS, element: <SettingsPage /> },
        { path: PROFILE, element: <ProfilePage /> },
        { path: ANNOUNCEMENTS, element: <Announcements /> },
      ],
    },
    { path: PROBLEM, element: <ProblemPage /> },
    { path: "*", element: <Navigate to={NOT_FOUND} replace /> },
  ]);
}
export default Router;
