import React, { useEffect } from "react";
import { Grid, Card, Paper, Typography, Box } from "@mui/material";
import Notice from "./Notice";

import { styled } from "@mui/system";
import General from "api/general";
import { Helmet } from "react-helmet-async";

const HeaderBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.main.white,
  padding: theme.spacing(2),
  textAlign: "left",
  boxShadow: theme.customShadows.primary,
}));

const Announcements = () => {
  const [announcements, setAnnouncements] = React.useState([]);
  useEffect(() => {
    const api = new General();
    api
      .listAnouncements()
      .then((res) => {
        console.log(res, announcements);
        setAnnouncements(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Announcements</title>
      </Helmet>
      <HeaderBox sx={{ px: 3, display: "flex" }}>
        <Typography variant="h3" gutterBottom paddingTop={1}>
          Announcements
        </Typography>
      </HeaderBox>
      <Box sx={{ mt: 2 }} />
      <Card
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Grid container spacing={2}>
          {announcements &&
            announcements.map((announcement, index) => (
              <Grid
                item
                key={announcement._id || index}
                xs={12}
                md={6}
                lg={4}
                xl={4}
              >
                <Notice notice={announcement} />
              </Grid>
            ))}
        </Grid>
      </Card>
    </>
  );
};

export default Announcements;
