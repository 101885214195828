const { useTheme } = require("@emotion/react");
const { Typography } = require("@mui/material");
const { default: StyledLink } = require("components/StyledLink");
import PropTypes from "prop-types";

const LinkedTypography = ({ text, variant, to, color }) => {
  const theme = useTheme();
  return (
    <Typography
      variant={variant}
      color={color || "inherit"}
      noWrap
      sx={{
        color: theme.palette.main.black,
        "&:hover": {
          color: theme.palette.main.darkAsh,
        },
      }}
    >
      <StyledLink to={to}>{text}</StyledLink>
    </Typography>
  );
};

LinkedTypography.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "inherit"]),
};

export default LinkedTypography;
