/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import Navbar from "components/Navbar";
import PanelLeft from "./PanelLeft";
import PanelRight from "./PanelRight";
import { Button, IconButton, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import Split from "react-split";
import IconMapper from "components/IconMapper";
import EditorSettings from "./dialogs/EditorSettings";
import "./splitpane.css";
import { useState, useEffect } from "react";
import {
  a11yProps,
  FilterLanguage,
  StyledNavbar,
  StyledTab,
  StyledTabs,
} from "./Utils";
import {
  editorCodeSnippets,
  editorCodeThemes,
  editorFontSizes,
  editorLanguagesAllowed,
  languageExtensions,
} from "constants/app";
import SubmissionStatusDialog from "components/SubmissionStatusDialog";
import { getCurrentUser } from "config/auth";
import { SIGN_IN } from "constants/route";
import General from "api/general";
import { Helmet } from "react-helmet-async";

const navbarItems = ["Problem", "Submission", "Editorial"];

const ProblemPage = () => {
  const [open, setOpen] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const theme = useTheme();
  // tabs
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [user, setUser] = useState(getCurrentUser());
  // language dropdown
  const [language, setLanguage] = useState(editorLanguagesAllowed[0]);
  // code editor
  const [code, setCode] = useState(editorCodeSnippets[language.toLowerCase()]);
  // theme
  const [editorTheme, setEditorTheme] = useState(editorCodeThemes[0]);
  const [fontSize, setFontSize] = useState(editorFontSizes[0]);
  const [problemName, setProblemName] = useState("");
  const [submitType, setSubmitType] = useState("");

  useEffect(() => {
    const urlParts = window.location.href.split("/");
    const name = urlParts[urlParts.length - 1];
    console.log(name);
    setProblemName(name);
  }, []);

  const onSubmitClick = (type) => {
    if (user.isAuthenticated) {
      setSubmitType(type);
      setOpenSubmission(true);
    } else window.location.href = SIGN_IN;
  };

  return (
    <>
      <Helmet>
        <title>Problem</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: theme.palette.main.background,
        }}
      >
        {/* the app bar with tabs */}
        <Navbar onOpenSidebar={() => setOpen(true)} gutter={3} padding={1.2} />
        <Box sx={{ backgroundColor: "transparent", mt: 2, mx: 3 }}>
          {/* top nav bar */}
          <StyledNavbar sx={{ py: 0, px: 2, display: "flex" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
            >
              {navbarItems.map((item, index) => (
                <StyledTab label={item} key={index} {...a11yProps(index)} />
              ))}
            </StyledTabs>
            <Box sx={{ flexGrow: 1 }} />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => setOpenEditor(true)}
                aria-label="delete"
              >
                <IconMapper name="settings" />
              </IconButton>

              {/* <IconButton aria-label="delete">
              <IconMapper name="fullScreen" />
            </IconButton> */}

              <FilterLanguage
                items={editorLanguagesAllowed}
                setLanguage={setLanguage}
                language={language}
                setCode={setCode}
                codeSnippets={editorCodeSnippets}
              />
            </Stack>
          </StyledNavbar>

          {/* split panel starts */}
          <Split
            className="split"
            minSize={0}
            gutterSize={14}
            style={{
              marginTop: "5px",
              position: "absolute",
              top: "139px",
              bottom: "72px",
              right: "25px",
              left: "25px",
            }}
          >
            {/* left panel */}
            <div
              style={{
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                flex: "auto",
              }}
            >
              <PanelLeft selectedTab={value} />
            </div>
            {/* right panel */}
            <div>
              <PanelRight
                setText={setCode}
                text={code}
                language={language.toLowerCase()}
                theme={editorTheme}
                fontSize={fontSize}
              />
            </div>
          </Split>
        </Box>

        {/* footer */}
        <Box
          component="footer"
          sx={{
            py: 3,
            mt: "auto",
            backgroundColor: theme.palette.main.lightAsh,
            display: "flex",
            bottom: 0,
            ml: 3.1,
            mr: 3.1,
          }}
        >
          <Box flexGrow={1} />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mr: 2,
              alignItems: "center",
            }}
          >
            {/* <IconButton aria-label="delete">
            <IconMapper name="uploadFile" color={theme.palette.main.black} />
          </IconButton> */}

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onSubmitClick("submitTest")}
            >
              Run tests
            </Button>

            <Button
              onClick={() => onSubmitClick("submit")}
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </Stack>
        </Box>
        {/* dialog: settings */}
        <EditorSettings
          open={openEditor}
          handleClose={() => setOpenEditor(false)}
          setTheme={setEditorTheme}
          setFontSize={setFontSize}
          fontSize={fontSize}
          editorTheme={editorTheme}
        />

        {/* dialog: submissionStatus */}
      </Box>
      {openSubmission && (
        <SubmissionStatusDialog
          open={openSubmission}
          problemName={problemName}
          language={languageExtensions[language.toLowerCase()]}
          source={code}
          handleClose={() => setOpenSubmission(false)}
          type={submitType}
        />
      )}
    </>
  );
};

export default ProblemPage;
