const editorCodeThemes = ["vs-dark", "light"];
const editorFontSizes = ["14px", "16px", "18px", "20px", "22px"];
const editorLanguagesAllowed = ["C", "Cpp", "Java", "Python"];
const languageExtensions = {
  c: "c",
  cpp: "cpp",
  java: "java",
  python: "py",
};

const extensionlanguages = {
  c: "C",
  cpp: "C++",
  java: "Java",
  py: "Python",
};

const editorCodeSnippets = {
  c: `#include <stdio.h>

int main()
{
  printf("Hello World!");
  return 0;
}
`,

  cpp: `#include <bits/stdc++.h>

using namespace std;

int main()
{
  cout << "Hello World!" << endl;
  return 0;
}
`,

  java: `import java.util.Scanner;  // Import the Scanner class

public class Main {    // class name must be Main
  public static void main(String[] args) {
      System.out.println("Hello World!");
  }
}
`,

  python: `print("Hello World!")
  `,
};

const landingPageFilter = ["Event", "Contest", "Blog", "Announcement"];
const ONLINE_JUDGES = [
  "Codeforces",
  "HackerRank",
  "Codechef",
  "Spoj",
  "Uva",
  "AtCoder",
  "Timus",
  "Toph",
];

// @todo: alamkhan
const ONLINE_JUDGES_LINK = [
  {
    name: "Codeforces",
    link: "https://codeforces.com/profile/",
  },
  {
    name: "HackerRank",
    link: "https://www.hackerrank.com/",
  },
  {
    name: "Codechef",
    link: "https://codechef.com/users/",
  },
  {
    name: "Spoj",
    link: "https://www.spoj.com/users/",
  },
  {
    name: "Uva",
    link: "https://uhunt.onlinejudge.org/id/",
  },
  {
    name: "AtCoder",
    link: "https://atcoder.jp/users/",
  },
  {
    name: "Timus",
    link: "https://acm.timus.ru/author.aspx?id=",
  },
  {
    name: "Toph",
    link: "https://toph.co/u/",
  },
];

const INSTITUTION = "AUST";

export {
  editorCodeThemes,
  editorFontSizes,
  editorLanguagesAllowed,
  editorCodeSnippets,
  landingPageFilter,
  languageExtensions,
  extensionlanguages,
  INSTITUTION,
  ONLINE_JUDGES,
  ONLINE_JUDGES_LINK,
};
