import React from "react";
// import PropTypes from "prop-types";
import IconMapper from "components/IconMapper";
import LinkedTypography from "components/LinkedTypography";
import {
  // PROBLEMS,
  // LEADERBOARD,
  SETTINGS,
  PROFILE,
  SUBMISSIONS,
} from "constants/route";
import httpStatus from "http-status";
import {
  Avatar,
  // Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import Auth from "api/auth";
import { getCurrentUser } from "config/auth";
import { useNavigate } from "react-router-dom";
import { notify } from "util";

const USER_DROPDOWN = {
  link: [
    {
      text: "Profile",
      to: PROFILE,
    },
    {
      text: "My Submissions",
      to: SUBMISSIONS,
    },
    // {
    //   text: "My Contests",
    //   to: PROBLEMS,
    // },
    {
      text: "Settings",
      to: SETTINGS,
    },
    // {
    //   text: "Help & Support",
    //   to: LEADERBOARD,
    // },
  ],
  authlink: {
    logout: {
      text: "Logout",
      to: "/",
    },
  },
};

/*
 * @author
 * whatever changes made in this UserDropDown component, also need to apply corresponding
 * changes in the AccordionUserDropDown component
 *
 */

const UserDropDown = () => {
  const theme = useTheme();
  const [anchorElUserDropDown, setAnchorElUserDropDown] = React.useState(null);
  const openUserDropdown = Boolean(anchorElUserDropDown);
  const navigate = useNavigate();

  const onSignOutClick = () => {
    console.log("sign out click");
    const auth = new Auth();
    auth
      .doSignOut()
      .then((res) => {
        console.log(res);
        if (res.status == httpStatus.OK) {
          window.location.href = USER_DROPDOWN.authlink.logout.to;
        }
      })
      .catch((error) => {
        console.log(error);
        notify(error, "error");
      });
  };

  const handleClickUserDropdown = (event) => {
    setAnchorElUserDropDown(event.currentTarget);
  };

  const handleCloseUserDropdown = () => {
    setAnchorElUserDropDown(null);
  };

  return (
    <Box>
      <Button
        sx={{
          px: theme.spacing(3),
          py: theme.spacing(1.5),
        }}
        fullWidth={true}
        variant="primary"
        color="light"
        onClick={handleClickUserDropdown}
        endIcon={
          openUserDropdown ? (
            <IconMapper name="arrowUp" color={theme.palette.main.blackShade} />
          ) : (
            <IconMapper
              name="arrowDown"
              color={theme.palette.main.blackShade}
            />
          )
        }
      >
        <Avatar
          sx={{
            mr: 2,
            backgroundColor: theme.palette.main.blackShade,
            width: 30,
            height: 30,
          }}
          src={getCurrentUser()?.profile?.avatar}
        />
        {/* <Typography variant="h4" color={theme.palette.main.purple}>
          Ahnaf1234
        </Typography> */}
      </Button>
      <Menu
        anchorEl={anchorElUserDropDown}
        id="account-menu"
        open={openUserDropdown}
        onClose={handleCloseUserDropdown}
        onClick={handleCloseUserDropdown}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: theme.customShadows.primary,
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {USER_DROPDOWN.link.map((item, index) => {
          let to = item.to;
          if (item.text === "My Submissions") {
            to = `${to}?$handle=${getCurrentUser().profile.handle}`;
            console.log(to);
          } else if (item.text === "Profile") {
            to = `${to}?handle=${getCurrentUser().profile.handle}`;
          }

          return (
            <MenuItem
              key={index}
              sx={{ justifyContent: "center" }}
              onClick={() => navigate(to)}
            >
              <LinkedTypography
                to={to}
                variant="h4"
                text={item.text}
                color="secondary"
              />
            </MenuItem>
          );
        })}
        {/* <Divider /> */}
        <MenuItem sx={{ p: 0 }}>
          <Button
            fullWidth
            size="medium"
            variant="secondary"
            color="dark"
            onClick={onSignOutClick}
            sx={{
              color: theme.palette.main.white,
              bgcolor: theme.palette.main.red,
              px: theme.spacing(6),
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            {USER_DROPDOWN.authlink.logout.text}
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  );
};

UserDropDown.propTypes = {};

export default UserDropDown;
