import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Stack, Toolbar, IconButton, Button } from "@mui/material";
import { MHidden } from "components/@material-extend";
import MuiAppBar from "@mui/material/AppBar";
import React, { useEffect } from "react";
import HamburgerMenu from "./Hamburger";
import {
  WINDOW_MARGIN,
  WINDOW_MARGIN_MD,
  WINDOW_MARGIN_SM,
} from "constants/dimens";
import {
  HOME,
  PROBLEMS,
  LEADERBOARD,
  // CONTEST,
  SUBMISSIONS,
} from "constants/route";

import LinkedTypography from "components/LinkedTypography";
import { SIGN_IN, SIGN_UP } from "constants/route";
import { useTheme } from "@emotion/react";
import IconMapper from "components/IconMapper";
import UserDropDown from "./UserDropDown";
import { getCurrentUser } from "config/auth";
import { useNavigate } from "react-router-dom";
import LogoSection from "components/LogoSection";

const ToolbarStyle = styled(Toolbar)(() => ({
  paddingLeft: 0,
}));

const AppBarStyle = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: theme.palette.main.white,
}));

// ----------------------------------------------------------------------

const HEADERS = {
  navbar: [
    {
      text: "Home",
      to: HOME,
    },
    // {
    //   text: "Contests",
    //   to: CONTEST,
    // },
    {
      text: "Problems",
      to: PROBLEMS,
    },
    {
      text: "Submissions",
      to: SUBMISSIONS,
    },
    {
      text: "Leaderboard",
      to: LEADERBOARD,
    },
  ],
  button: {
    signin: {
      text: "Sign In",
      to: SIGN_IN,
    },
    signup: {
      text: "Sign Up",
      to: SIGN_UP,
    },
  },
};

export default function Navbar({ gutter, padding }) {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
  }, []);

  const onHamburgerClick = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setMenuOpen(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarStyle position="relative">
        <ToolbarStyle
          disableGutters
          sx={{
            mx: gutter,
            py: padding || 1.5,
            margin: gutter ? "" : WINDOW_MARGIN,

            "@media (max-width: 1500px)": {
              margin: gutter ? "" : WINDOW_MARGIN_MD,
            },

            [theme.breakpoints.down("sm")]: {
              margin: gutter ? "" : WINDOW_MARGIN_SM,
            },
          }}
        >
          {/* <LinkedTypography to="/" variant="h1" text="AUST OJ" /> */}
          <LogoSection />

          {/* normal view */}
          <MHidden width={"lgDown"}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <Box sx={{ paddingRight: theme.spacing(4) }} />

              {HEADERS.navbar.map((item, index) => (
                <Box
                  key={index}
                  sx={{ paddingRight: theme.spacing(1.5) }}
                  onClick={() => navigate(item.to)}
                >
                  <LinkedTypography
                    to={item.to}
                    variant="subtitle1"
                    text={item.text}
                    color="primary"
                  />
                </Box>
              ))}
            </Stack>
            {/* auth buttons */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
              sx={{ marginLeft: "auto" }}
            >
              {user.isAuthenticated ? (
                /*
                 * @author
                 * whatever changes made in this UserDropDown component, also need to apply corresponding
                 * changes in the AccordionUserDropDown component
                 *
                 */
                <UserDropDown />
              ) : (
                <Button
                  size="large"
                  variant="primary"
                  color="dark"
                  onClick={() =>
                    (window.location.href = HEADERS.button.signin.to)
                  }
                >
                  {HEADERS.button.signin.text}
                </Button>
              )}
            </Stack>
          </MHidden>
          {/* in mobile view: hamburger icon when device in mobile view */}

          <MHidden width={"lgUp"}>
            <IconButton
              sx={{ ml: "auto" }}
              size="large"
              onClick={onHamburgerClick}
            >
              <IconMapper name="menu" />
            </IconButton>
          </MHidden>

          <HamburgerMenu
            isMenuOpen={isMenuOpen}
            handleCloseNavMenu={handleCloseNavMenu}
            HEADERS={HEADERS}
            user={user}
          />
        </ToolbarStyle>
      </AppBarStyle>
    </Box>
  );
}

HamburgerMenu.propTypes = {
  setMenuOpen: PropTypes.func,
};

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  caller: PropTypes.oneOf(["auth", "authUser"]),
  gutter: PropTypes.number, // OPTIONAL: called from problemPage
  padding: PropTypes.number, // OPTIONAL: called from problemPage
};
