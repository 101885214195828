import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Chip, Divider, Tooltip, Typography } from "@mui/material";
import IconMapper from "components/IconMapper";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "number",
    numeric: true,
    disablePadding: true,
    label: "No #",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "accuracy",
    numeric: true,
    disablePadding: false,
    label: "Accuracy",
  },
  {
    id: "difficulty",
    numeric: false,
    disablePadding: false,
    label: "Difficulty",
  },
];

const rowRadius = 10;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // add border to only first and last child
  backgroundColor: theme.palette.main.lightAsh,

  "&:first-child": {
    borderTopLeftRadius: rowRadius,
    borderBottomLeftRadius: rowRadius,
  },
  "&:last-child": {
    borderTopRightRadius: rowRadius,
    borderBottomRightRadius: rowRadius,
  },

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.body1.fontSize,
    color: "secondary",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.main.softBlue,
  },

  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.main.softPurple,
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, user } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (!user.isAuthenticated && headCell.id === "status") {
            return <React.Fragment key={headCell.id} />;
          }
          return (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === "title" || headCell.id == "number"
                  ? "left"
                  : "center"
              }
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant="h6" color="secondary">
                  {headCell.label}
                </Typography>

                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const GetDifficultyChip = (difficulty) => {
  if (!difficulty) {
    return <Chip label="Unknown" color="secondary" />;
  }
  switch (difficulty.toLowerCase()) {
    case "easy":
      return (
        <Chip
          label="Easy"
          color="success"
          sx={{
            color: "white",
          }}
        />
      );
    case "medium":
      return (
        <Chip
          label="Medium"
          color="warning"
          sx={{
            color: "secondary",
          }}
        />
      );
    case "hard":
      return <Chip label="Hard" color="error" />;
    default:
      return <Chip label="Unknown" color="secondary" />;
  }
};

export default function ProblemsTable({ data, user }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("number");
  const [page, setPage] = React.useState(0);
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calcAccuracy = (problem) => {
    if (
      problem?.totalSubmissions == 0 ||
      problem.totalSubmissions == undefined
    ) {
      return 0 + " %";
    }
    let res = problem.acceptedSubmissions / problem.totalSubmissions;

    // make res to 2 dec place
    res = (res * 100).toFixed(2);
    return res + " %";
  };

  const onProblemClick = (problem) => {
    navigate(`/problem/${problem.uniqueTitle}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, backgroundColor: "transparent" }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              user={user}
            />

            <TableBody
              sx={{
                backgroundColor: "transparent",
              }}
            >
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    px: 0,
                    pt: 1,
                    pb: 2,
                  }}
                >
                  <Divider />
                </TableCell>
              </TableRow>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow
                      tabIndex={-1}
                      key={index}
                      sx={{
                        borderBottom: 5,
                        borderColor: theme.palette.main.white,
                        cursor: "pointer",
                      }}
                      onClick={() => onProblemClick(row)}
                    >
                      <StyledTableCell
                        component="th"
                        id={index}
                        scope="row"
                        align="left"
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{row.title}</StyledTableCell>

                      {user.isAuthenticated && (
                        <StyledTableCell align="center">
                          {row.status ? (
                            <IconMapper
                              name="check"
                              color={theme.palette.main.green}
                            />
                          ) : (
                            <IconMapper
                              name="remove"
                              color={theme.palette.main.darkAsh}
                            />
                          )}
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center">
                        <Tooltip
                          title={
                            "Solved: " +
                            row.acceptedSubmissions +
                            " Submissions: " +
                            row.totalSubmissions
                          }
                          placement="top"
                          arrow
                          sx={{
                            m: 0,
                          }}
                        >
                          <div>{calcAccuracy(row)}</div>
                        </Tooltip>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {GetDifficultyChip(row.difficulty)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  user: PropTypes.object,
};

ProblemsTable.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object,
};
