import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import TableCell from "@mui/material/TableCell";
import { TableRow } from "@mui/material";

const rowRadius = 10;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // add border to only first and last child
  "&:first-child": {
    borderTopLeftRadius: rowRadius,
    borderBottomLeftRadius: rowRadius,
  },
  "&:last-child": {
    borderTopRightRadius: rowRadius,
    borderBottomRightRadius: rowRadius,
  },

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.body1.fontSize,
    color: "secondary",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.main.softBlue,
  },

  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.main.softPurple,
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export { StyledTableCell, StyledTableRow };
