import * as React from "react";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

const PanelRight = ({ language, theme, text, setText, fontSize }) => {
  const [modifiedText,setModifiedText]=React.useState({
          data:({
            c:[{
                  text:"",
                  flag:0
                }],
            cpp:[{
                  text:"",
                  flag:0
                }],
            java:[{
                  text:"",
                  flag:0
                }],
            python:[{
                  text:"",
                  flag:0
                }]
          })
});

  if(modifiedText.data[language].flag)
  {
      text=modifiedText.data[language].text;
  }
  

  return (
    <Editor
      theme={theme || "vs-dark"}
      defaultLanguage="c"
      language={language || "c"}
      value={text}

      onChange={(e) => {

        setText(e);
      
        setModifiedText((prevState) => {
            prevState.data[language].text = e;
            prevState.data[language].flag = 1;
            return({
              ...prevState
            })
          })
      
      }}
      defaultValue={text}
      options={{
        fontSize: fontSize || 15,
        minimap: {
          enabled: false,
        },
      }}
    />
  );
};

PanelRight.propTypes = {
  language: PropTypes.oneOf(["c", "cpp", "python", "java"]),
  theme: PropTypes.oneOf(["vs-dark", "light"]),
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
};

export default PanelRight;
